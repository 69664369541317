import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalConstant } from '@shared/GlobalConstant';
import { TotalEnergyCost } from '../../_models/vehicleCost/energyCost/totalEnergyCost';
import { Financials } from '../../_models/vehicleCost/financials/financials';
import { FinancialsEnergy } from '../../_models/vehicleCost/financials/financialsEnergy';
import { CountryEmissionFactor } from '../../_models/vehicleCost/impactCo2/countryEmissionFactor';
import { EnergyIncentivesTax } from '../../_models/vehicleCost/incentives/energyIncentive';
import { EnergyTransportCost } from '../../_models/vehicleCost/transport/energyTransportCost';

@Injectable({
	providedIn: 'root',
})
export class VehicleCostFormService {
	public detailsFinancialForm: FormGroup | null;
	public monthlyFeesForm: FormGroup | null;
	public energyCostForm: FormGroup;
	public incentivesForm: FormGroup;
	public impactCo2Form: FormGroup;
	public transportCostForm: FormGroup;

	constructor(private formBuild: FormBuilder) {}

	/** Init Financial detail or monthly Form Reactive */
	public initFinancialForm(financials: FinancialsEnergy, comparisonId: number, isDetail: boolean) {
		if (isDetail) {
			this.initDetailsFinancialForm(financials, comparisonId);
		} else {
			this.initMonthlyFinancialForm(financials, comparisonId);
		}
	}

	/** Init Details-Financial Form Reactive */
	private initDetailsFinancialForm(financials: FinancialsEnergy, comparisonId: number) {
		this.monthlyFeesForm = null;
		let electric = financials.electric as Financials;
		let diesel = financials.diesel as Financials;
		let gas = financials.gas as Financials;
		let electricComp = financials.electricComp as Financials;

		const elecFinancialForm = this.formBuild.group({
			truck: [electric.truck, [Validators.required, Validators.min(0.0001)]],
			body: [electric.body, [Validators.required, Validators.min(0)]],
			residualValue: [electric.residualValue, [Validators.required, Validators.min(0)]],
			interest: [electric.interest, [Validators.required, Validators.min(0)]],
			rmContract: [electric.rmContract, [Validators.required, Validators.min(0)]],
			charger: [electric.charger, [Validators.required, Validators.min(0)]],
			smartCharge: [electric.smartCharge, [Validators.required, Validators.min(0)]],
		});

		switch (comparisonId) {
			case GlobalConstant.COMPTYPE_ED:
				const dieselFinancialForm = this.formBuild.group({
					truck: [diesel.truck, [Validators.required, Validators.min(1)]],
					body: [diesel.body, [Validators.required, Validators.min(0)]],
					residualValue: [diesel.residualValue, [Validators.required, Validators.min(0)]],
					interest: [diesel.interest, [Validators.required, Validators.min(0)]],
					rmContract: [diesel.rmContract, [Validators.required, Validators.min(0)]],
				});
				this.detailsFinancialForm = this.formBuild.group({
					electric: elecFinancialForm,
					diesel: dieselFinancialForm,
				});
				break;
			case GlobalConstant.COMPTYPE_EDG:
				const dieselFinancialForm2 = this.formBuild.group({
					truck: [diesel.truck, [Validators.required, Validators.min(1)]],
					body: [diesel.body, [Validators.required, Validators.min(0)]],
					residualValue: [diesel.residualValue, [Validators.required, Validators.min(0)]],
					interest: [diesel.interest, [Validators.required, Validators.min(0)]],
					rmContract: [diesel.rmContract, [Validators.required, Validators.min(0)]],
				});
				const gasFinancialForm2 = this.formBuild.group({
					truck: [gas.truck, [Validators.required, Validators.min(1)]],
					body: [gas.body, [Validators.required, Validators.min(0)]],
					residualValue: [gas.residualValue, [Validators.required, Validators.min(0)]],
					interest: [gas.interest, [Validators.required, Validators.min(0)]],
					rmContract: [gas.rmContract, [Validators.required, Validators.min(0)]],
				});
				this.detailsFinancialForm = this.formBuild.group({
					electric: elecFinancialForm,
					diesel: dieselFinancialForm2,
					gas: gasFinancialForm2,
				});
				break;
			case GlobalConstant.COMPTYPE_EG:
				const gasFinancialForm = this.formBuild.group({
					truck: [gas.truck, [Validators.required, Validators.min(1)]],
					body: [gas.body, [Validators.required, Validators.min(0)]],
					residualValue: [gas.residualValue, [Validators.required, Validators.min(0)]],
					interest: [gas.interest, [Validators.required, Validators.min(0)]],
					rmContract: [gas.rmContract, [Validators.required, Validators.min(0)]],
				});
				this.detailsFinancialForm = this.formBuild.group({
					electric: elecFinancialForm,
					gas: gasFinancialForm,
				});
				break;
			case GlobalConstant.COMPTYPE_E:
				this.detailsFinancialForm = this.formBuild.group({
					electric: elecFinancialForm,
				});
				break;
			case GlobalConstant.COMPTYPE_EE:
				const electricCompFinancialForm = this.formBuild.group({
					truck: [electricComp.truck, [Validators.required, Validators.min(1)]],
					body: [electricComp.body, [Validators.required, Validators.min(0)]],
					residualValue: [electricComp.residualValue, [Validators.required, Validators.min(0)]],
					interest: [electricComp.interest, [Validators.required, Validators.min(0)]],
					rmContract: [electricComp.rmContract, [Validators.required, Validators.min(0)]],
					charger: [electricComp.charger, [Validators.required, Validators.min(0)]],
					smartCharge: [electricComp.smartCharge, [Validators.required, Validators.min(0)]],
				});
				this.detailsFinancialForm = this.formBuild.group({
					electric: elecFinancialForm,
					electricComp: electricCompFinancialForm,
				});
				break;
		}
	}

	/** Init Monthly-Financial Form Reactive */
	private initMonthlyFinancialForm(financials: FinancialsEnergy, comparisonId: number) {
		this.detailsFinancialForm = null;
		financials.electric = financials.electric as Financials;
		financials.diesel = financials.diesel as Financials;
		financials.gas = financials.gas as Financials;
		financials.electricComp = financials.electricComp as Financials;
		const elecFinancialForm = this.formBuild.group({
			monthlyFeesTruck: [financials.electric.monthlyFeesTruck, [Validators.required, Validators.min(1)]],
			inclBody: [financials.electric.inclBody, [Validators.required, Validators.min(0)]],
			inclUpfront: [financials.electric.inclUpfront, [Validators.required, Validators.min(0)]],
			monthlyFeeCharger: [financials.electric.monthlyFeeCharger, [Validators.required, Validators.min(0)]],
			inclUpfrontCharger: [financials.electric.inclUpfrontCharger, [Validators.required, Validators.min(0)]],
			rmContract: [financials.electric.rmContract, [Validators.required, Validators.min(0)]],
			smartCharge: [financials.electric.smartCharge, [Validators.required, Validators.min(0)]],
		});

		switch (comparisonId) {
			case GlobalConstant.COMPTYPE_ED:
				const dieselFinancialForm = this.formBuild.group({
					monthlyFeesTruck: [financials.diesel.monthlyFeesTruck, [Validators.required, Validators.min(1)]],
					inclBody: [financials.diesel.inclBody, [Validators.required, Validators.min(0)]],
					inclUpfront: [financials.diesel.inclUpfront, [Validators.required, Validators.min(0)]],
					rmContract: [financials.diesel.rmContract, [Validators.required, Validators.min(0)]],
				});
				this.monthlyFeesForm = this.formBuild.group({
					electric: elecFinancialForm,
					diesel: dieselFinancialForm,
				});
				break;
			case GlobalConstant.COMPTYPE_EDG:
				const dieselFinancialForm2 = this.formBuild.group({
					monthlyFeesTruck: [financials.diesel.monthlyFeesTruck, [Validators.required, Validators.min(1)]],
					inclBody: [financials.diesel.inclBody],
					inclUpfront: [financials.diesel.inclUpfront],
					rmContract: [financials.diesel.rmContract],
				});
				const gasFinancialForm2 = this.formBuild.group({
					monthlyFeesTruck: [financials.gas.monthlyFeesTruck, [Validators.required, Validators.min(1)]],
					inclBody: [financials.gas.inclBody, [Validators.required, Validators.min(0)]],
					inclUpfront: [financials.gas.inclUpfront, [Validators.required, Validators.min(0)]],
					rmContract: [financials.gas.rmContract, [Validators.required, Validators.min(0)]],
				});
				this.monthlyFeesForm = this.formBuild.group({
					electric: elecFinancialForm,
					diesel: dieselFinancialForm2,
					gas: gasFinancialForm2,
				});
				break;
			case GlobalConstant.COMPTYPE_EG:
				const gasFinancialForm = this.formBuild.group({
					monthlyFeesTruck: [financials.gas.monthlyFeesTruck, [Validators.required, Validators.min(1)]],
					inclBody: [financials.gas.inclBody, [Validators.required, Validators.min(0)]],
					inclUpfront: [financials.gas.inclUpfront, [Validators.required, Validators.min(0)]],
					rmContract: [financials.gas.rmContract, [Validators.required, Validators.min(0)]],
				});
				this.monthlyFeesForm = this.formBuild.group({
					electric: elecFinancialForm,
					gas: gasFinancialForm,
				});
				break;
			case GlobalConstant.COMPTYPE_E:
				this.monthlyFeesForm = this.formBuild.group({
					electric: elecFinancialForm,
				});
				break;
			case GlobalConstant.COMPTYPE_EE:
				const elecCompFinancialForm = this.formBuild.group({
					monthlyFeesTruck: [financials.electricComp.monthlyFeesTruck, [Validators.required, Validators.min(1)]],
					inclBody: [financials.electricComp.inclBody, [Validators.required, Validators.min(0)]],
					inclUpfront: [financials.electricComp.inclUpfront, [Validators.required, Validators.min(0)]],
					monthlyFeeCharger: [financials.electricComp.monthlyFeeCharger, [Validators.required, Validators.min(0)]],
					inclUpfrontCharger: [financials.electricComp.inclUpfrontCharger, [Validators.required, Validators.min(0)]],
					rmContract: [financials.electricComp.rmContract, [Validators.required, Validators.min(0)]],
					smartCharge: [financials.electricComp.smartCharge, [Validators.required, Validators.min(0)]],
				});
				this.monthlyFeesForm = this.formBuild.group({
					electric: elecFinancialForm,
					electricComp: elecCompFinancialForm,
				});
				break;
		}
	}

	/** Init Energy Cost Form Reactive */
	public initEnergyCostForm(totalEnergyCost: TotalEnergyCost, comparisonId: number) {
		const elecEnergyCostForm = this.formBuild.group({
			cost: [totalEnergyCost.electric.cost.toFixed(3), [Validators.required, Validators.min(0.0001)]],
			yearlyInflation: [totalEnergyCost.electric.yearlyInflation, [Validators.required, Validators.min(0)]],
			dieselCost: [
				totalEnergyCost.electric.dieselCost ? totalEnergyCost.electric.dieselCost : 0,
				[Validators.required, Validators.min(0)],
			],
			adblue: [totalEnergyCost.electric.adblue ? totalEnergyCost.electric.adblue : 0, [Validators.required, Validators.min(0)]],
		});

		switch (comparisonId) {
			case GlobalConstant.COMPTYPE_ED:
				const dieselEnergyCostForm = this.formBuild.group({
					cost: [totalEnergyCost.diesel.cost.toFixed(3)],
					yearlyInflation: [totalEnergyCost.diesel.yearlyInflation, [Validators.required, Validators.min(0)]],
					dieselCost: [
						totalEnergyCost.diesel.dieselCost ? totalEnergyCost.diesel.dieselCost : 0,
						[Validators.required, Validators.min(0.0001)],
					],
					adblue: [
						totalEnergyCost.diesel.adblue ? totalEnergyCost.diesel.adblue : 0,
						[Validators.required, Validators.min(0.0001)],
					],
				});
				this.energyCostForm = this.formBuild.group({
					electric: elecEnergyCostForm,
					diesel: dieselEnergyCostForm,
				});
				break;
			case GlobalConstant.COMPTYPE_EDG:
				const dieselEnergyCostForm2 = this.formBuild.group({
					cost: [totalEnergyCost.diesel.cost.toFixed(3)],
					yearlyInflation: [totalEnergyCost.diesel.yearlyInflation, [Validators.required, Validators.min(0)]],
					dieselCost: [
						totalEnergyCost.diesel.dieselCost ? totalEnergyCost.diesel.dieselCost : 0,
						[Validators.required, Validators.min(0.0001)],
					],
					adblue: [
						totalEnergyCost.diesel.adblue ? totalEnergyCost.diesel.adblue : 0,
						[Validators.required, Validators.min(0.0001)],
					],
				});
				const gasEnergyCostForm2 = this.formBuild.group({
					cost: [totalEnergyCost.gas.cost.toFixed(3), [Validators.required, Validators.min(0.0001)]],
					yearlyInflation: [totalEnergyCost.gas.yearlyInflation, [Validators.required, Validators.min(0)]],
					dieselCost: [
						totalEnergyCost.gas.dieselCost ? totalEnergyCost.gas.dieselCost : 0,
						[Validators.required, Validators.min(0)],
					],
					adblue: [totalEnergyCost.gas.adblue ? totalEnergyCost.gas.adblue : 0, [Validators.required, Validators.min(0)]],
				});
				this.energyCostForm = this.formBuild.group({
					electric: elecEnergyCostForm,
					diesel: dieselEnergyCostForm2,
					gas: gasEnergyCostForm2,
				});
				break;
			case GlobalConstant.COMPTYPE_EG:
				const gasEnergyCostForm = this.formBuild.group({
					cost: [totalEnergyCost.gas.cost.toFixed(3), [Validators.required, Validators.min(0.0001)]],
					yearlyInflation: [totalEnergyCost.gas.yearlyInflation, [Validators.required, Validators.min(0)]],
					dieselCost: [
						totalEnergyCost.gas.dieselCost ? totalEnergyCost.gas.dieselCost : 0,
						[Validators.required, Validators.min(0)],
					],
					adblue: [totalEnergyCost.gas.adblue ? totalEnergyCost.gas.adblue : 0, [Validators.required, Validators.min(0)]],
				});
				this.energyCostForm = this.formBuild.group({
					electric: elecEnergyCostForm,
					gas: gasEnergyCostForm,
				});
				break;
			case GlobalConstant.COMPTYPE_E:
				this.energyCostForm = this.formBuild.group({
					electric: elecEnergyCostForm,
				});
				break;
			case GlobalConstant.COMPTYPE_EE:
				const elecCompEnergyCostForm = this.formBuild.group({
					cost: [totalEnergyCost.electricComp.cost.toFixed(3), [Validators.required, Validators.min(0.0001)]],
					yearlyInflation: [totalEnergyCost.electricComp.yearlyInflation, [Validators.required, Validators.min(0)]],
					dieselCost: [
						totalEnergyCost.electricComp.dieselCost ? totalEnergyCost.electricComp.dieselCost : 0,
						[Validators.required, Validators.min(0)],
					],
					adblue: [
						totalEnergyCost.electricComp.adblue ? totalEnergyCost.electricComp.adblue : 0,
						[Validators.required, Validators.min(0)],
					],
				});
				this.energyCostForm = this.formBuild.group({
					electric: elecEnergyCostForm,
					electricComp: elecCompEnergyCostForm,
				});
				break;
		}
	}

	/** Init ImpactCo2 Form Reactive */
	public initImpactCo2Form(impactCo2: CountryEmissionFactor, comparisonId: number, energySubtype?: string) {
		switch (comparisonId) {
			case GlobalConstant.COMPTYPE_ED:
				this.impactCo2Form = this.formBuild.group({
					averageCost: [
						impactCo2.averageCostState || impactCo2.averageCostState != null ? impactCo2.averageCost : 0,
						[Validators.required, Validators.min(0)],
					],
					averageCostState: [impactCo2.averageCostState ?? false],
					electric: this.formBuild.group({
						emissionFactor: [impactCo2.electric?.[0]?.value || 0],
					}),
					diesel: this.formBuild.group({
						emissionFactor: [impactCo2.diesel?.[0]?.value || 0],
					}),
				});
				break;
			case GlobalConstant.COMPTYPE_EDG:
				this.impactCo2Form = this.formBuild.group({
					averageCost: [
						impactCo2.averageCostState || impactCo2.averageCostState != null ? impactCo2.averageCost : 0,
						[Validators.required, Validators.min(0)],
					],
					averageCostState: [impactCo2.averageCostState ?? false],
					electric: this.formBuild.group({
						emissionFactor: [impactCo2.electric?.[0]?.value || 0],
					}),
					diesel: this.formBuild.group({
						emissionFactor: [impactCo2.diesel?.[0]?.value || 0],
					}),
					gas: this.formBuild.group({
						emissionFactor: [impactCo2.gas?.[0]?.value || 0],
					}),
				});
				break;
			case GlobalConstant.COMPTYPE_EG:
				this.impactCo2Form = this.formBuild.group({
					averageCost: [
						impactCo2.averageCostState || impactCo2.averageCostState != null ? impactCo2.averageCost : 0,
						[Validators.required, Validators.min(0)],
					],
					averageCostState: [impactCo2.averageCostState ?? false],
					electric: this.formBuild.group({
						emissionFactor: [impactCo2.electric?.[0]?.value || 0],
					}),
					gas: this.formBuild.group({
						emissionFactor: [impactCo2.gas?.[0]?.value || 0],
					}),
				});
				break;
			case GlobalConstant.COMPTYPE_E:
				this.impactCo2Form = this.formBuild.group({
					averageCost: [
						impactCo2.averageCostState || impactCo2.averageCostState != null ? impactCo2.averageCost : 0,
						[Validators.required, Validators.min(0)],
					],
					averageCostState: [impactCo2.averageCostState ?? false],
					electric: this.formBuild.group({
						emissionFactor: [impactCo2.electric?.[0]?.value || 0],
					}),
				});
				break;
		}
	}

	/** Init Incentives Cost Form Reactive */
	public initIncentivesForm(energyIncentivesTax: EnergyIncentivesTax, comparisonId: number) {
		const elecIncentiveForm = this.formBuild.group({
			upfront: [energyIncentivesTax.electric.upfront],
			taxReduct: [energyIncentivesTax.electric.taxReduct],
			notUpfront: [energyIncentivesTax.electric.notUpfront],
			toll: [energyIncentivesTax.electric.toll],
			sharePercent: [energyIncentivesTax.electric.sharePercent],
			shareKm: [energyIncentivesTax.electric.shareKm],
		});
		switch (comparisonId) {
			case GlobalConstant.COMPTYPE_ED:
				const dieselIncentiveForm = this.formBuild.group({
					upfront: [energyIncentivesTax.diesel.upfront],
					taxReduct: [energyIncentivesTax.diesel.taxReduct],
					notUpfront: [energyIncentivesTax.diesel.notUpfront],
					toll: [energyIncentivesTax.diesel.toll],
					sharePercent: [energyIncentivesTax.diesel.sharePercent],
					shareKm: [energyIncentivesTax.diesel.shareKm],
				});
				this.incentivesForm = this.formBuild.group({
					incomeTax: [energyIncentivesTax.incomeTax, [Validators.required, Validators.min(0)]],
					electric: elecIncentiveForm,
					diesel: dieselIncentiveForm,
				});
				break;
			case GlobalConstant.COMPTYPE_EDG:
				const dieselIncentiveForm2 = this.formBuild.group({
					upfront: [energyIncentivesTax.diesel.upfront],
					taxReduct: [energyIncentivesTax.diesel.taxReduct],
					notUpfront: [energyIncentivesTax.diesel.notUpfront],
					toll: [energyIncentivesTax.diesel.toll],
					sharePercent: [energyIncentivesTax.diesel.sharePercent],
					shareKm: [energyIncentivesTax.diesel.shareKm],
				});
				const gasIncentiveForm2 = this.formBuild.group({
					upfront: [energyIncentivesTax.gas.upfront],
					taxReduct: [energyIncentivesTax.gas.taxReduct],
					notUpfront: [energyIncentivesTax.gas.notUpfront],
					toll: [energyIncentivesTax.gas.toll],
					sharePercent: [energyIncentivesTax.gas.sharePercent],
					shareKm: [energyIncentivesTax.gas.shareKm],
				});
				this.incentivesForm = this.formBuild.group({
					incomeTax: [energyIncentivesTax.incomeTax, [Validators.required, Validators.min(0)]],
					electric: elecIncentiveForm,
					diesel: dieselIncentiveForm2,
					gas: gasIncentiveForm2,
				});
				break;
			case GlobalConstant.COMPTYPE_EG:
				const gasIncentiveForm = this.formBuild.group({
					upfront: [energyIncentivesTax.gas.upfront],
					taxReduct: [energyIncentivesTax.gas.taxReduct],
					notUpfront: [energyIncentivesTax.gas.notUpfront],
					toll: [energyIncentivesTax.gas.toll],
					sharePercent: [energyIncentivesTax.gas.sharePercent],
					shareKm: [energyIncentivesTax.gas.shareKm],
				});
				this.incentivesForm = this.formBuild.group({
					incomeTax: [energyIncentivesTax.incomeTax, [Validators.required, Validators.min(0)]],
					electric: elecIncentiveForm,
					gas: gasIncentiveForm,
				});
				break;
			case GlobalConstant.COMPTYPE_E:
				this.incentivesForm = this.formBuild.group({
					incomeTax: [energyIncentivesTax.incomeTax, [Validators.required, Validators.min(0)]],
					electric: elecIncentiveForm,
				});
				break;
			case GlobalConstant.COMPTYPE_EE:
				const elecCompIncentiveForm = this.formBuild.group({
					upfront: [energyIncentivesTax.electricComp.upfront],
					taxReduct: [energyIncentivesTax.electricComp.taxReduct],
					notUpfront: [energyIncentivesTax.electricComp.notUpfront],
					toll: [energyIncentivesTax.electricComp.toll],
					sharePercent: [energyIncentivesTax.electricComp.sharePercent],
					shareKm: [energyIncentivesTax.electricComp.shareKm],
				});
				this.incentivesForm = this.formBuild.group({
					incomeTax: [energyIncentivesTax.incomeTax, [Validators.required, Validators.min(0)]],
					electric: elecIncentiveForm,
					electricComp: elecCompIncentiveForm,
				});
				break;
		}
	}

	/** Init Transport Cost Form Reactive */
	public initTransportCostForm(energyTransportCost: EnergyTransportCost, comparisonId: number) {
		const elecTransportForm = this.formBuild.group({
			body: [energyTransportCost.electric.body ? energyTransportCost.electric.body : 0, [Validators.required, Validators.min(0)]],
			tyres: [energyTransportCost.electric.tyres ? energyTransportCost.electric.tyres : 0, [Validators.required, Validators.min(0)]],
			insurance: [
				energyTransportCost.electric.insurance ? energyTransportCost.electric.insurance : 0,
				[Validators.required, Validators.min(0)],
			],
			road: [energyTransportCost.electric.road ? energyTransportCost.electric.road : 0, [Validators.required, Validators.min(0)]],
			driver: [
				energyTransportCost.electric.driver ? energyTransportCost.electric.driver : 0,
				[Validators.required, Validators.min(0)],
			],
			fixed: [energyTransportCost.electric.fixed ? energyTransportCost.electric.fixed : 0, [Validators.required, Validators.min(0)]],
			variable: [
				energyTransportCost.electric.variable ? energyTransportCost.electric.variable : 0,
				[Validators.required, Validators.min(0)],
			],
		});

		switch (comparisonId) {
			case GlobalConstant.COMPTYPE_ED:
				const dieselTransportForm = this.formBuild.group({
					body: [energyTransportCost.diesel.body ? energyTransportCost.diesel.body : 0, [Validators.required, Validators.min(0)]],
					tyres: [
						energyTransportCost.diesel.tyres ? energyTransportCost.diesel.tyres : 0,
						[Validators.required, Validators.min(0)],
					],
					insurance: [
						energyTransportCost.diesel.insurance ? energyTransportCost.diesel.insurance : 0,
						[Validators.required, Validators.min(0)],
					],
					road: [energyTransportCost.diesel.road ? energyTransportCost.diesel.road : 0, [Validators.required, Validators.min(0)]],
					driver: [
						energyTransportCost.diesel.driver ? energyTransportCost.diesel.driver : 0,
						[Validators.required, Validators.min(0)],
					],
					fixed: [
						energyTransportCost.diesel.fixed ? energyTransportCost.diesel.fixed : 0,
						[Validators.required, Validators.min(0)],
					],
					variable: [
						energyTransportCost.diesel.variable ? energyTransportCost.diesel.variable : 0,
						[Validators.required, Validators.min(0)],
					],
				});
				this.transportCostForm = this.formBuild.group({
					electric: elecTransportForm,
					diesel: dieselTransportForm,
				});
				break;
			case GlobalConstant.COMPTYPE_EDG:
				const dieselTransportForm2 = this.formBuild.group({
					body: [energyTransportCost.diesel.body ? energyTransportCost.diesel.body : 0, [Validators.required, Validators.min(0)]],
					tyres: [
						energyTransportCost.diesel.tyres ? energyTransportCost.diesel.tyres : 0,
						[Validators.required, Validators.min(0)],
					],
					insurance: [
						energyTransportCost.diesel.insurance ? energyTransportCost.diesel.insurance : 0,
						[Validators.required, Validators.min(0)],
					],
					road: [energyTransportCost.diesel.road ? energyTransportCost.diesel.road : 0, [Validators.required, Validators.min(0)]],
					driver: [
						energyTransportCost.diesel.driver ? energyTransportCost.diesel.driver : 0,
						[Validators.required, Validators.min(0)],
					],
					fixed: [
						energyTransportCost.diesel.fixed ? energyTransportCost.diesel.fixed : 0,
						[Validators.required, Validators.min(0)],
					],
					variable: [
						energyTransportCost.diesel.variable ? energyTransportCost.diesel.variable : 0,
						[Validators.required, Validators.min(0)],
					],
				});
				const gasTransportForm2 = this.formBuild.group({
					body: [energyTransportCost.gas.body ? energyTransportCost.gas.body : 0, [Validators.required, Validators.min(0)]],
					tyres: [energyTransportCost.gas.tyres ? energyTransportCost.gas.tyres : 0, [Validators.required, Validators.min(0)]],
					insurance: [
						energyTransportCost.gas.insurance ? energyTransportCost.gas.insurance : 0,
						[Validators.required, Validators.min(0)],
					],
					road: [energyTransportCost.gas.road ? energyTransportCost.gas.road : 0, [Validators.required, Validators.min(0)]],
					driver: [energyTransportCost.gas.driver ? energyTransportCost.gas.driver : 0, [Validators.required, Validators.min(0)]],
					fixed: [energyTransportCost.gas.fixed ? energyTransportCost.gas.fixed : 0, [Validators.required, Validators.min(0)]],
					variable: [
						energyTransportCost.gas.variable ? energyTransportCost.gas.variable : 0,
						[Validators.required, Validators.min(0)],
					],
					refuellingtime: [
						energyTransportCost.gas.refuellingtime ? energyTransportCost.gas.refuellingtime : 0,
						[Validators.required, Validators.min(0)],
					],
				});
				this.transportCostForm = this.formBuild.group({
					electric: elecTransportForm,
					diesel: dieselTransportForm2,
					gas: gasTransportForm2,
				});
				break;
			case GlobalConstant.COMPTYPE_EG:
				const gasTransportForm = this.formBuild.group({
					body: [energyTransportCost.gas.body ? energyTransportCost.gas.body : 0, [Validators.required, Validators.min(0)]],
					tyres: [energyTransportCost.gas.tyres ? energyTransportCost.gas.tyres : 0, [Validators.required, Validators.min(0)]],
					insurance: [
						energyTransportCost.gas.insurance ? energyTransportCost.gas.insurance : 0,
						[Validators.required, Validators.min(0)],
					],
					road: [energyTransportCost.gas.road ? energyTransportCost.gas.road : 0, [Validators.required, Validators.min(0)]],
					driver: [energyTransportCost.gas.driver ? energyTransportCost.gas.driver : 0, [Validators.required, Validators.min(0)]],
					fixed: [energyTransportCost.gas.fixed ? energyTransportCost.gas.fixed : 0, [Validators.required, Validators.min(0)]],
					variable: [
						energyTransportCost.gas.variable ? energyTransportCost.gas.variable : 0,
						[Validators.required, Validators.min(0)],
					],
					refuellingtime: [
						energyTransportCost.gas.refuellingtime ? energyTransportCost.gas.refuellingtime : 0,
						[Validators.required, Validators.min(0)],
					],
				});
				this.transportCostForm = this.formBuild.group({
					electric: elecTransportForm,
					gas: gasTransportForm,
				});
				break;
			case GlobalConstant.COMPTYPE_E:
				this.transportCostForm = this.formBuild.group({
					electric: elecTransportForm,
				});
				break;
			case GlobalConstant.COMPTYPE_EE:
				const elecCompTransportForm = this.formBuild.group({
					body: [
						energyTransportCost.electricComp.body ? energyTransportCost.electricComp.body : 0,
						[Validators.required, Validators.min(0)],
					],
					tyres: [
						energyTransportCost.electricComp.tyres ? energyTransportCost.electricComp.tyres : 0,
						[Validators.required, Validators.min(0)],
					],
					insurance: [
						energyTransportCost.electricComp.insurance ? energyTransportCost.electricComp.insurance : 0,
						[Validators.required, Validators.min(0)],
					],
					road: [
						energyTransportCost.electricComp.road ? energyTransportCost.electricComp.road : 0,
						[Validators.required, Validators.min(0)],
					],
					driver: [
						energyTransportCost.electricComp.driver ? energyTransportCost.electricComp.driver : 0,
						[Validators.required, Validators.min(0)],
					],
					fixed: [
						energyTransportCost.electricComp.fixed ? energyTransportCost.electricComp.fixed : 0,
						[Validators.required, Validators.min(0)],
					],
					variable: [
						energyTransportCost.electricComp.variable ? energyTransportCost.electricComp.variable : 0,
						[Validators.required, Validators.min(0)],
					],
				});
				this.transportCostForm = this.formBuild.group({
					electric: elecTransportForm,
					electricComp: elecCompTransportForm,
				});
				break;
		}
	}
}
