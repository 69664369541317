import { EnergyConfig } from '@_models/vehicleInfo/vehicleConfig/energyConfig';

export class VehicleConfig {
	[key: string]: any;
	id: number;
	electric: EnergyConfig;
	diesel: EnergyConfig;
	gas: EnergyConfig;
	electricComp: EnergyConfig;
}
