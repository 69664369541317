import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * RT Sidebar service
 */
@Injectable({
  providedIn: 'root',
})
export class RtSidebarService {
  /**
   * Representation of the status of the sidebar
   */
  isOpened$: Subject<boolean> = new Subject<boolean>();

  /**
   * Method used to provide sidebar state
   * @returns an observable indicating if the sidebar is opened or closed
   */
  listenToIsOpened(): Observable<boolean> {
    return this.isOpened$.asObservable();
  }

  /**
   * Method used to change the sidebar state (isOpened)
   * @param opened true to display sidebar, else false
   */
  toggleSidebar(isOpened: boolean): void {
    this.isOpened$.next(isOpened);
  }
}
