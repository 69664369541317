import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { GlobalConstant } from '@shared/GlobalConstant';
import { ModelEnergy } from '@shared/models/business/modelEnergy';
import { EnergyUsage } from '../../_models/vehicleInfo/energyUsage/energyUsage';
import { VehicleConfig } from '../../_models/vehicleInfo/vehicleConfig/vehicleConfig';
import { VehicleUsage } from '../../_models/vehicleInfo/vehicleUsage/vehicleUsage';
import { ContractInformation } from '@_models/vehicleInfo/vehicleUsage/contract-information';

@Injectable({
	providedIn: 'root',
})
export class VehicleInformationFormService {
	public energyUsageForm: FormGroup;
	public vehicleConfigForm: FormGroup;
	public vehicleUsageForm: FormGroup;
	public models: ModelEnergy[];

	constructor(private formBuild: FormBuilder) {}

	/** Init Vehicule-Usage Form Reactive */
	public initVehicleUsageForm(vehicleUsage: VehicleUsage, comparisonId: number) {
		this.vehicleUsageForm = this.formBuild.group({
			application: [vehicleUsage.application, [Validators.required]],
			bodyType: [vehicleUsage.bodyType ? vehicleUsage.bodyType : null],
			usageType: [vehicleUsage.usageType ? vehicleUsage.usageType : null],
			operatingDaysPerYear: [vehicleUsage.operatingDaysPerYear, [Validators.required, Validators.min(1)]],
			shiftPerDay: [vehicleUsage.shiftPerDay, [Validators.required, Validators.min(1)]],
			dailyMileage: [vehicleUsage.dailyMileage, [Validators.required, Validators.min(1)]],
			yearlyMileage: [vehicleUsage.yearlyMileage, [Validators.required, Validators.min(1)]],
			electric: this.createContractInformationGroup(vehicleUsage.electric),
		});

		this.initContractInformationFormByEnergy(this.vehicleUsageForm, vehicleUsage, comparisonId);
	}

	private initContractInformationFormByEnergy(formGroup: FormGroup, vehicleUsage: VehicleUsage, comparisonId: number): void {
		switch (comparisonId) {
			case GlobalConstant.COMPTYPE_ED:
				formGroup.addControl(GlobalConstant.DIESEL, this.createContractInformationGroup(vehicleUsage.diesel));
				break;
			case GlobalConstant.COMPTYPE_EDG:
				formGroup.addControl(GlobalConstant.DIESEL, this.createContractInformationGroup(vehicleUsage.diesel));
				formGroup.addControl(GlobalConstant.GAS, this.createContractInformationGroup(vehicleUsage.gas));
				break;
			case GlobalConstant.COMPTYPE_EG:
				formGroup.removeControl(GlobalConstant.DIESEL);
				formGroup.addControl(GlobalConstant.GAS, this.createContractInformationGroup(vehicleUsage.gas));
				break;
			case GlobalConstant.COMPTYPE_EE:
				formGroup.addControl(GlobalConstant.ELEC_COMP, this.createContractInformationGroup(vehicleUsage.electricComp));
				break;
			default:
				break;
		}
	}

	private createContractInformationGroup(contractInformation: ContractInformation): FormGroup {
		return this.formBuild.group({
			year: [contractInformation.year, [Validators.required, Validators.min(1)]],
			month: [contractInformation.month, [Validators.required, Validators.min(1)]],
		});
	}

	// TODO: Find a way to improve the init form from loading
	/** Init Vehicle-Config Form Reactive */
	public initVehicleConfigForm(vehicleConfig: VehicleConfig, comparisonId: number) {
		switch (comparisonId) {
			case GlobalConstant.COMPTYPE_ED:
				vehicleConfig.diesel.model.name = vehicleConfig.diesel.model.code;
				this.vehicleConfigForm = this.formBuild.group({
					electric: this.formBuild.group({
						battery: [vehicleConfig.electric.battery, [Validators.required]],
						model: [vehicleConfig.electric.model ? vehicleConfig.electric.model : null, [Validators.required]],
						payload: [vehicleConfig.electric.payload, [Validators.required, Validators.min(0)]],
						ptac: [vehicleConfig.electric.ptac ? vehicleConfig.electric.ptac : '', [Validators.required]],
						pto: [vehicleConfig.electric?.pto, [Validators.required]],
					}),
					diesel: this.formBuild.group({
						energySubtype: [
							vehicleConfig.diesel.energySubtype ? vehicleConfig.diesel.energySubtype : null,
							[Validators.required],
						],
						model: [vehicleConfig.diesel.model ? vehicleConfig.diesel.model?.name : '', [Validators.required]],
						payload: [vehicleConfig.diesel.payload, [Validators.required, Validators.min(0)]],
						ptac: [vehicleConfig.diesel.ptac ? vehicleConfig.diesel.ptac : '', [Validators.required]],
						pto: [vehicleConfig.diesel?.pto, [Validators.required]],
					}),
				});
				break;
			case GlobalConstant.COMPTYPE_EDG:
				vehicleConfig.diesel.model.name = vehicleConfig.diesel.model.code;
				vehicleConfig.gas.model.name = vehicleConfig.gas.model.code;
				this.vehicleConfigForm = this.formBuild.group({
					electric: this.formBuild.group({
						battery: [vehicleConfig.electric.battery, [Validators.required]],
						model: [vehicleConfig.electric.model ? vehicleConfig.electric.model : null, [Validators.required]],
						payload: [vehicleConfig.electric.payload, [Validators.required, Validators.min(0)]],
						ptac: [vehicleConfig.electric.ptac ? vehicleConfig.electric.ptac : '', [Validators.required]],
						pto: [vehicleConfig.electric?.pto, [Validators.required]],
					}),
					diesel: this.formBuild.group({
						energySubtype: [
							vehicleConfig.diesel.energySubtype ? vehicleConfig.diesel.energySubtype : null,
							[Validators.required],
						],
						model: [vehicleConfig.diesel.model ? vehicleConfig.diesel.model?.name : '', [Validators.required]],
						payload: [vehicleConfig.diesel.payload, [Validators.required, Validators.min(0)]],
						ptac: [vehicleConfig.diesel.ptac ? vehicleConfig.diesel.ptac : '', [Validators.required]],
						pto: [vehicleConfig.diesel?.pto, [Validators.required]],
					}),
					gas: this.formBuild.group({
						model: [vehicleConfig.gas.model ? vehicleConfig.gas.model?.name : '', [Validators.required]],
						payload: [vehicleConfig.gas.payload, [Validators.required, Validators.min(0)]],
						ptac: [vehicleConfig.gas.ptac ? vehicleConfig.gas.ptac : '', [Validators.required]],
						pto: [vehicleConfig.gas?.pto, [Validators.required]],
					}),
				});
				break;
			case GlobalConstant.COMPTYPE_EG:
				vehicleConfig.gas.model.name = vehicleConfig.gas.model.code;
				this.vehicleConfigForm = this.formBuild.group({
					electric: this.formBuild.group({
						battery: [vehicleConfig.electric.battery, [Validators.required]],
						model: [vehicleConfig.electric.model ? vehicleConfig.electric.model : null, [Validators.required]],
						payload: [vehicleConfig.electric.payload, [Validators.required, Validators.min(0)]],
						ptac: [vehicleConfig.electric.ptac ? vehicleConfig.electric.ptac : '', [Validators.required]],
						pto: [vehicleConfig.electric?.pto, [Validators.required]],
					}),
					gas: this.formBuild.group({
						model: [vehicleConfig.gas.model ? vehicleConfig.gas.model?.name : '', [Validators.required]],
						payload: [vehicleConfig.gas.payload, [Validators.required, Validators.min(0)]],
						ptac: [vehicleConfig.gas.ptac ? vehicleConfig.gas.ptac : '', [Validators.required]],
						pto: [vehicleConfig.gas?.pto, [Validators.required]],
					}),
				});
				break;
			case GlobalConstant.COMPTYPE_E:
				this.vehicleConfigForm = this.formBuild.group({
					electric: this.formBuild.group({
						battery: [vehicleConfig.electric.battery, [Validators.required]],
						model: [vehicleConfig.electric.model ? vehicleConfig.electric.model : null, [Validators.required]],
						payload: [vehicleConfig.electric.payload, [Validators.required, Validators.min(0)]],
						ptac: [vehicleConfig.electric.ptac ? vehicleConfig.electric.ptac : '', [Validators.required]],
						pto: [vehicleConfig.electric?.pto, [Validators.required]],
					}),
				});
				break;
			case GlobalConstant.COMPTYPE_EE:
				vehicleConfig.electricComp.model.name = vehicleConfig.electricComp.model.code;
				this.vehicleConfigForm = this.formBuild.group({
					electric: this.formBuild.group({
						battery: [vehicleConfig.electric.battery, [Validators.required]],
						model: [vehicleConfig.electric.model ? vehicleConfig.electric.model : null, [Validators.required]],
						payload: [vehicleConfig.electric.payload, [Validators.required, Validators.min(0)]],
						ptac: [vehicleConfig.electric.ptac ? vehicleConfig.electric.ptac : '', [Validators.required]],
						pto: [vehicleConfig.electric?.pto, [Validators.required]],
					}),
					electricComp: this.formBuild.group({
						battery: [vehicleConfig.electricComp.battery ? vehicleConfig.electricComp.battery.code : '', [Validators.required]],
						model: [vehicleConfig.electricComp.model ? vehicleConfig.electricComp.model?.name : '', [Validators.required]],
						payload: [vehicleConfig.electricComp.payload, [Validators.required, Validators.min(0)]],
						ptac: [vehicleConfig.electricComp.ptac ? vehicleConfig.electricComp.ptac : '', [Validators.required]],
						pto: [vehicleConfig.electricComp?.pto, [Validators.required]],
					}),
				});
				break;
		}
	}

	public addEngineNumberInVehicleConfigForm(energy: string, vehicleConfig: VehicleConfig) {
		if (energy != GlobalConstant.ELECTRIC) {
			(this.vehicleConfigForm.controls[energy] as FormGroup)?.addControl(
				'engineNumber',
				this.formBuild.control(
					vehicleConfig.electricComp.engineNumber ? vehicleConfig.electricComp.engineNumber.name : '',
					Validators.required
				)
			);
		} else {
			(this.vehicleConfigForm.controls[energy] as FormGroup)?.addControl(
				'engineNumber',
				this.formBuild.control(
					vehicleConfig.electric.engineNumber ? vehicleConfig.electric.engineNumber : null,
					Validators.required
				)
			);
		}
	}

	public removeEngineNumberInVehicleConfigForm(energy: string) {
		(this.vehicleConfigForm.controls[energy] as FormGroup).removeControl('engineNumber');
	}

	/**
	 * Replace in Vehicle config form PTAC by GCW depending of range id
	 * @param rangeId
	 * @param vehicleConfig
	 * @param energy
	 * @private
	 */
	public switchPtacToGcwByEnergy(rangeId: number, vehicleConfig: VehicleConfig | any, energy: string) {
		if (rangeId === GlobalConstant.RANGE_HDV) {
			(this.vehicleConfigForm.controls[energy] as FormGroup).removeControl('ptac');
			(this.vehicleConfigForm.controls[energy] as FormGroup).addControl(
				'gcw',
				this.formBuild.control(vehicleConfig[energy].gcw ? vehicleConfig[energy].gcw : '', Validators.required)
			);
		} else {
			(this.vehicleConfigForm.controls[energy] as FormGroup).removeControl('gcw');
			(this.vehicleConfigForm.controls[energy] as FormGroup).addControl(
				'ptac',
				this.formBuild.control(vehicleConfig[energy].gcw ? vehicleConfig[energy].gcw : '', Validators.required)
			);
		}
	}

	/** Init Energy-Usage Form Reactive */
	public initEnergyUsageForm(energyUsage: EnergyUsage, comparisonId: number) {
		const elecUsageForm = this.formBuild.group({
			consumption: [energyUsage.electric.consumption ?? 0, [Validators.required, this.isNaNValidator(), Validators.min(0.001)]],
		});
		switch (comparisonId) {
			case GlobalConstant.COMPTYPE_ED:
				const dieselUsageForm = this.formBuild.group({
					adblue: [energyUsage.diesel.adblue, [Validators.required, this.isNaNValidator(), Validators.min(0.001)]],
					consumption: [energyUsage.diesel.consumption ?? 0, [Validators.required, this.isNaNValidator(), Validators.min(0.001)]],
				});

				this.energyUsageForm = this.formBuild.group({
					electric: elecUsageForm,
					diesel: dieselUsageForm,
				});
				break;
			case GlobalConstant.COMPTYPE_EDG:
				const dieselUsageForm2 = this.formBuild.group({
					adblue: [energyUsage.diesel.adblue, [Validators.required, this.isNaNValidator(), Validators.min(0.001)]],
					consumption: [energyUsage.diesel.consumption ?? 0, [Validators.required, this.isNaNValidator(), Validators.min(0.001)]],
				});

				const gasUsageForm2 = this.formBuild.group({
					consumption: [energyUsage.gas.consumption ?? 0, [Validators.required, this.isNaNValidator(), Validators.min(0.001)]],
				});

				this.energyUsageForm = this.formBuild.group({
					electric: elecUsageForm,
					diesel: dieselUsageForm2,
					gas: gasUsageForm2,
				});
				break;
			case GlobalConstant.COMPTYPE_EG:
				const gasUsageForm = this.formBuild.group({
					consumption: [energyUsage.gas.consumption ?? 0, [Validators.required, this.isNaNValidator(), Validators.min(0.001)]],
				});

				this.energyUsageForm = this.formBuild.group({
					electric: elecUsageForm,
					gas: gasUsageForm,
				});
				break;
			case GlobalConstant.COMPTYPE_E:
				this.energyUsageForm = this.formBuild.group({
					electric: elecUsageForm,
				});
				break;
			case GlobalConstant.COMPTYPE_EE:
				const electricCompUsageForm = this.formBuild.group({
					consumption: [
						energyUsage.electricComp.consumption ?? 0,
						[Validators.required, this.isNaNValidator(), Validators.min(0.001)],
					],
				});

				this.energyUsageForm = this.formBuild.group({
					electric: elecUsageForm,
					electricComp: electricCompUsageForm,
				});
				break;
		}
	}

	private isNaNValidator(): ValidatorFn {
		return (control: AbstractControl) => {
			const isValid = !isNaN(control.value) && control.value !== null;
			if (isValid) {
				return null;
			}
			return { IsNaN: true };
		};
	}
}
