import { ContractDurationResultDto } from '@_dto/global/vehicleInfoDto/usageLimit/contractDurationResultDto';

export class ContractDuration {
	simulation: number;
	maxDuration: number;
	status: string;

	constructor(contractDurationResultDto: ContractDurationResultDto) {
		this.simulation = contractDurationResultDto ? contractDurationResultDto.simulation : 0;
		this.maxDuration = contractDurationResultDto ? contractDurationResultDto.maxDuration : 0;
		this.status = contractDurationResultDto ? contractDurationResultDto.status : '';
	}
}
