import { EnergyConsumption } from 'src/app/layout/comparator/_models/vehicleInfo/energyUsage/energyConsumption';

export class EnergyUsage {
	[key: string]: any;
	id!: number;
	electric: EnergyConsumption;
	diesel: EnergyConsumption;
	gas: EnergyConsumption;
	electricComp: EnergyConsumption;
}
