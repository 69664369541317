<div class="relative w-full">
	<button
		*ngIf="isDisabled"
		id="{{ type.toLowerCase() + '_' + (energy ? energy : '') }}"
		class="bg-readonly flex justify-between items-center w-full h-9 text-sm rounded-md shadow-sm text-left border border-solid border-gray-300"
	>
		<div class="pl-2 text-xs text-gray-400" *ngIf="(!value || value?.name === '') && (!value2 || value2?.name === '')">
			{{ "global.selectA" | translate }}
		</div>
		<div
			class="pl-2"
			*ngIf="
				value &&
				[
					'EnergySubtype',
					'IUnits',
					'IRange',
					'IComparisonType',
					'EngineNumber',
					'Application',
					'Model',
					'Ptac',
					'BatteryVersion',
					'Pto',
					'Gcw',
					'BodyType',
					'UsageType'
				].includes(type)
			"
		>
			{{ value.name }}
		</div>

		<div class="flex justify-center align-middle h-full mr-3">
			<svg class="w-4" viewBox="0 0 18 10" fill="none">
				<path d="M1 1L9 9L17 1" stroke="#252525" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
		</div>
	</button>

	<button
		*ngIf="!isDisabled"
		id="{{ type.toLowerCase() + '_' + (energy ? energy : '') }}"
		class="flex justify-between items-center w-full h-9 bg-white text-gray-800 text-sm rounded-md shadow-sm text-left cursor-pointer select-none border border-solid border-gray-300 focus:ring-1 focus:ring-gray-400"
		(mousedown)="displayDropdown()"
		(focusout)="looseFocus()"
	>
		<div class="pl-2 text-xs text-gray-400" *ngIf="!value && !value2">{{ "global.selectA" | translate }}</div>
		<div
			class="pl-2"
			*ngIf="
				value &&
				[
					'EnergySubtype',
					'IUnits',
					'IRange',
					'IComparisonType',
					'EngineNumber',
					'Application',
					'Model',
					'Ptac',
					'BatteryVersion',
					'Pto',
					'Gcw',
					'BodyType',
					'UsageType'
				].includes(type)
			"
		>
			{{ value.name }}
		</div>
		<div class="pl-2" *ngIf="type === 'ICurrency'">{{ value2.code }}</div>
		<div class="flex justify-center align-middle h-full mr-3">
			<svg class="w-4" viewBox="0 0 18 10" fill="none" id="{{ type.toLowerCase() + '_arrow_' + (energy ? energy : '') }}">
				<path d="M1 1L9 9L17 1" stroke="#252525" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
		</div>
	</button>

	<ul
		class="absolute w-full pl-0 top-full z-10 mt-0.5 bg-white text-sm shadow-md max-h-80 rounded overflow-auto select-none"
		(mouseover)="isHovered = true"
		(mouseout)="isHovered = false"
		[hidden]="!toggleDropdown"
	>
		<span
			*ngIf="
				[
					'EnergySubtype',
					'IUnits',
					'IRange',
					'IComparisonType',
					'EngineNumber',
					'Application',
					'Model',
					'Ptac',
					'BatteryVersion',
					'Pto',
					'Gcw',
					'BodyType',
					'UsageType'
				].includes(type)
			"
		>
			<li
				class="flex justify-between text-gray-800 py-2 cursor-pointer hover:bg-zinc-100"
				*ngFor="let item of items; let index = index"
				id="{{ type.toLowerCase() + '_' + index + (energy ? energy : '') }}"
				(mousedown)="onSelect(item)"
			>
				<span class="pl-3 h-full">{{ item.name }}</span>
			</li>
		</span>
		<span *ngIf="type === 'ICurrency'">
			<li
				class="flex justify-between text-gray-800 py-2 cursor-pointer hover:bg-zinc-100"
				*ngFor="let item of items2; let index = index"
				id="{{ type.toLowerCase() + '_' + index }}"
				(mousedown)="onSelect(item)"
			>
				<span class="pl-3 h-full">{{ item.code }}</span>
			</li>
		</span>
	</ul>
</div>
