export class TransportCost {
	body: number;
	tyres: number;
	insurance: number;
	road: number;
	driver: number;
	fixed: number;
	variable: number;
	refuellingtime?: number; // Only gas
}
