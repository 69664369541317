import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { RtBreadcrumbs } from '../../models/rt-breadcrumbs.model';

/**
 * Component representing the breadcrumbs to help the user navigate to parent routes
 */
@Component({
  selector: 'rt-breadcrumbs',
  templateUrl: './rt-breadcrumbs.component.html',
  styleUrls: ['./rt-breadcrumbs.component.scss'],
})
export class RtBreadcrumbsComponent implements OnInit {
  /**
   * the navigation breadcrumbs
   */
  breadcrumbs: Array<RtBreadcrumbs> = [];

  /**
   * Root page of the application
   */
  @Input() rootPage!: string;

  /**
   * Subject used to avoid memory leaks on component destruction
   */
  destroy$ = new Subject<any>();

  /**
   * Default constructor
   * @param router used to catch routing events to update the breadcrumbs
   * @param activatedRoute used to build the breadcrumbs
   */
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  /**
   * On init :
   * - Build the breadcrumbs
   * - Listen to routing events to update the breadcrumb
   */
  ngOnInit(): void {
    this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe(() => (this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root)));
  }

  /**
   * Recursive method creating the breadcrumbs
   * @param route current route
   * @param url default url
   * @param breadcrumbs breadcumb array
   * @returns created breadcrumbs
   */
  createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Array<RtBreadcrumbs> = []
  ): Array<RtBreadcrumbs> {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      breadcrumbs.unshift({
        label: '',
        url: this.rootPage,
      });
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');

      if (routeURL) {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data['title'];
      const hasOwnUrl = child.snapshot.data['hasOwnUrl'] || false;

      if (label) {
        if (hasOwnUrl) {
          breadcrumbs.push({ label, url });
        } else {
          breadcrumbs.push({ label });
        }
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }
}
