import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-select-string',
	templateUrl: './select-string.component.html',
	styleUrls: ['./select-string.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SelectStringComponent,
		},
	],
})
export class SelectStringComponent implements ControlValueAccessor {
	@Input() items: string[];
	@Input() public value: string;

	public toggleDropdown = false;
	public isHovered = false;
	public touched = false;
	public disabled = false;

	onChange: any = () => {};
	onTouch: any = () => {};

	constructor() {}

	// writeValue() gets triggered whenever the parent component somehow changes the value of myFormControl
	public writeValue(input: string) {
		this.value = input;
	}

	//Inform the parent component about changes inside of your CustomInputComponent, propagate changes of the value to the outside
	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	//Give Angular the means to understand when the custom form control is touched
	public registerOnTouched(onTouched: any): void {
		this.onTouch = onTouched;
	}

	public markAsTouched(): void {
		if (!this.touched) {
			this.onTouch();
			this.touched = true;
		}
	}
	public displayDropdown(): void {
		this.markAsTouched();
		if (!this.toggleDropdown) this.toggleDropdown = !this.toggleDropdown;
	}

	public looseFocus() {
		if (!this.isHovered) this.toggleDropdown = false;
	}

	public onSelect(item: string): void {
		this.markAsTouched();
		if (!this.disabled) {
			this.value = item;

			this.onChange(this.value);
			this.toggleDropdown = !this.toggleDropdown;
			this.isHovered = false;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}
}
