import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICountry } from '@shared/models/business/iCountry';
import { GlobalInformation } from '../../_models/globalInformation';
import { IComparisonType } from '@shared/models/business/iComparisonType';

@Injectable({
	providedIn: 'root',
})
export class GlobalInformationForm {
	public globalInfoForm: FormGroup;

	constructor(private formBuild: FormBuilder) {}

	/** Init Global Info Form Reactive */
	public initGlobalForm(globalInfo: GlobalInformation) {
		this.globalInfoForm = this.formBuild.group({
			contactName: [globalInfo.contactName, [Validators.required]],
			contactMail: [globalInfo.contactMail, [Validators.required]],
			companyName: [globalInfo.companyName, [Validators.required]],
			caseName: [globalInfo.caseName, [Validators.required]],
			country: [globalInfo.country as ICountry, [Validators.required]],
			currency: [globalInfo.currency, [Validators.required]],
			range: [globalInfo.range, [Validators.required]],
			comparisonType: [globalInfo.comparisonType as IComparisonType, [Validators.required]],
			unit: [globalInfo.unit, [Validators.required]],
			isTco: [globalInfo.isTco, [Validators.required]],
			isDetail: [globalInfo.isDetail, [Validators.required]],
			customerView: [globalInfo.customerView, [Validators.required]],
		});
	}
}
