import { EnergyConfig } from '@_models/vehicleInfo/vehicleConfig/energyConfig';
import { EnergyConsumption } from '@_models/vehicleInfo/energyUsage/energyConsumption';
import { Financials } from '@_models/vehicleCost/financials/financials';
import { EnergyCost } from '@_models/vehicleCost/energyCost/energyCost';
import { IncentivesTaxe } from '@_models/vehicleCost/incentives/incentivesTaxe';
import { TransportCost } from '@_models/vehicleCost/transport/transportCost';
import { ContractInformation } from '@_models/vehicleInfo/vehicleUsage/contract-information';

export class EnergySessionStorage {
	[key: string]: any;
	type: string;
	energyConfig!: EnergyConfig;
	energyUsage!: EnergyConsumption;
	financials: Financials;
	energyCost!: EnergyCost;
	energyIncentivesTaxe!: IncentivesTaxe;
	transportCost!: TransportCost;
	energyContractInformation!: ContractInformation;
}
