import { Financials } from './financials';

export class FinancialsEnergy {
	[key: string]: any;
	public electric: Financials;
	public diesel: Financials;
	public gas: Financials;
	public electricComp: Financials;
	public feedbacks?: string;
}
