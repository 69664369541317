import { Directive, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';

/**
 * Directive responsible of transforming a link
 */
@Directive({
  selector: '[rtLink]',
})
export class RtLinkDirective implements OnInit {
  @HostBinding('class') classes = 'rt-link';
  @Input() color!: 'red';
  @HostBinding('class.red') get red(): boolean {
    return this.color === 'red';
  }
  @Input() direction!: 'back' | 'forward';

  /**
   * Default constructor for the directive
   * @param elementRef ElementRef accessor
   * @param renderer2 Renderer2 accessor
   */
  constructor(private elementRef: ElementRef, private renderer2: Renderer2) {}

  /**
   * On init :
   * - add ZMDI icon to let users know that it's an external link
   * - make sure that the link will be opened in a new tab
   */
  ngOnInit(): void {
    if (this.elementRef.nativeElement.href) {
      this.renderer2.setAttribute(this.elementRef.nativeElement, 'target', '_blank');
      const zmdiIcon = this.renderer2.createElement('span');
      this.renderer2.addClass(zmdiIcon, 'zmdi');
      this.renderer2.addClass(zmdiIcon, 'zmdi-open-in-new');
      this.renderer2.appendChild(this.elementRef.nativeElement, zmdiIcon);
    } else {
      switch (this.direction) {
        case 'back':
          const zmdiIconLeft = this.renderer2.createElement('span');
          this.renderer2.addClass(zmdiIconLeft, 'zmdi');
          this.renderer2.addClass(zmdiIconLeft, 'zmdi-arrow-left');
          this.renderer2.insertBefore(
            this.elementRef.nativeElement,
            zmdiIconLeft,
            this.elementRef.nativeElement.firstChild
          );
          break;
        case 'forward':
          const zmdiIconRight = this.renderer2.createElement('span');
          this.renderer2.addClass(zmdiIconRight, 'zmdi');
          this.renderer2.addClass(zmdiIconRight, 'zmdi-arrow-right');
          this.renderer2.appendChild(this.elementRef.nativeElement, zmdiIconRight);
          break;
        default:
          return;
      }
    }
  }
}
