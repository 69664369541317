import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
	@Output() switchValue = new EventEmitter();
	@Input() public modalIsOpen: boolean = false;
	@Input() public alertMaps: Map<String, Array<String[]>> = new Map<String, Array<String[]>>();
	@Input() public property: string;

	constructor() {}

	ngOnInit(): void {}

	public closeModal() {
		this.modalIsOpen = false;
		this.switchValue.emit(this.modalIsOpen);
	}
}
