import {
  Directive,
  HostBinding,
  HostListener,
  ElementRef,
  ContentChild,
  ContentChildren,
  QueryList,
  EventEmitter,
  AfterContentInit,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * RT accordion toggle directive
 */
@Directive({
  selector: '[rtAccordionToggle]',
})
export class RtAccordionToggleDirective {
  /**
   * Event emitted when a click occured on the toggle
   */
  public toggle: EventEmitter<void> = new EventEmitter<void>();

  /**
   * ...
   */
  @HostBinding('class.accordion-toggle') accordionToggleClass = true;

  /**
   * ...
   * @param event ...
   */
  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    this.onToggle(event);
  }

  /**
   * ...
   * @param event ...
   */
  @HostListener('keyup.enter', ['$event'])
  public onEnter(event: any): void {
    this.onToggle(event);
  }

  /**
   * ...
   * @param event ...
   */
  @HostListener('keyup.space', ['$event'])
  public onSpace(event: any): void {
    this.onToggle(event);
  }

  /**
   * ...
   * @param event ...
   */
  private onToggle(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.toggle.emit();
  }
}

/**
 * RT accordion content directive
 */
@Directive({
  selector: '[rtAccordionContent]',
})
export class RtAccordionContentDirective {
  /**
   * ...
   */
  @HostBinding('class.accordion-content') accordionContentClass = true;

  /**
   * Default constructor
   * @param el, element reference
   */
  constructor(public el: ElementRef) {}
}

/**
 * Rt accordion global directive
 */
@Directive({
  selector: '[rtAccordion]',
})
export class RtAccordionDirective implements AfterContentInit, OnDestroy {
  destroy$ = new Subject<void>();

  /**
   * ...
   */
  @HostBinding('class.accordion') accordionClass = true;
  /**
   * ...
   */
  @HostBinding('class.opened')
  /**
   * ...
   */
  public opened: boolean;

  /**
   * ...
   */
  public toggle: EventEmitter<void> = new EventEmitter<void>();
  /**
   * ...
   */
  public maxHeight: number;

  /**
   * ...
   */
  @ContentChild(RtAccordionContentDirective, { static: true })
  contentEl!: RtAccordionContentDirective;
  /**
   * ...
   */
  @ContentChild(RtAccordionToggleDirective, { static: true })
  toggleEl!: RtAccordionToggleDirective;
  /**
   * ...
   */
  @ContentChildren(RtAccordionDirective, { descendants: true })
  accordionsEl!: QueryList<RtAccordionDirective>;

  /**
   * Default constructor
   * @param el ...
   */
  constructor(protected el: ElementRef) {
    this.opened = false;
    this.maxHeight = 0;
  }

  /**
   * On init :
   * -
   */
  ngAfterContentInit(): void {
    this.toggleEl.toggle.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!this.opened) {
        this.open();
      } else {
        this.close();
      }
      this.toggle.emit();
    });

    for (let i = 1; i < this.accordionsEl.length; i++) {
      this.accordionsEl
        .toArray()
        [i].toggle.pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.toggleSubAccordion(this.accordionsEl.toArray()[i]);
        });
    }
  }

  /**
   * ...
   */
  public open(): void {
    const panel = this.contentEl.el.nativeElement;
    panel.style.maxHeight = '100%';
    this.maxHeight = panel.scrollHeight;
    this.opened = true;
  }

  /**
   * ...
   * @param accordion ...
   */
  public toggleSubAccordion(accordion: RtAccordionDirective): void {
    const panel = this.contentEl.el.nativeElement;
    panel.style.maxHeight = panel.scrollHeight + accordion.maxHeight + 'px';
    this.maxHeight = panel.scrollHeight + accordion.maxHeight;
  }

  /**
   * ...
   */
  public close(): void {
    this.contentEl.el.nativeElement.style.maxHeight = null;
    this.maxHeight = 0;
    this.opened = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
