import { Component, Input } from '@angular/core';

/**
 * Component responsible of displaying sub-menu items
 */
@Component({
  selector: 'rt-sub-menu-item',
  templateUrl: './rt-sub-menu-item.component.html',
  styleUrls: ['./rt-sub-menu-item.component.scss'],
})
export class RtSubMenuItemComponent {
  /**
   * Sub menu item label
   */
  @Input() label!: string;

  /**
   * Targeted url
   */
  @Input() url!: string;

  /**
   * Used to know if the sub menu item is having sub-sub-menu items or not, default set to false
   */
  @Input() hasChildren = false;
}
