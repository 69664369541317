import { EmissionFactor } from './emissionFactor';

export class CountryEmissionFactor {
	[key: string]: any;
	electric: EmissionFactor[];
	diesel: EmissionFactor[];
	gas: EmissionFactor[];
	averageCost: number;
	averageCostState: boolean;
}
