import { Application } from '@shared/models/business/application';
import { BodyType } from '@shared/models/business/bodyType';
import { UsageType } from '@shared/models/business/usageType';
import { ContractInformation } from '@_models/vehicleInfo/vehicleUsage/contract-information';

export class VehicleUsage {
	[key: string]: any;
	id: number;
	application: Application;
	bodyType?: BodyType;
	usageType?: UsageType;
	dailyMileage: number;
	yearlyMileage: number;
	operatingDaysPerYear: number;
	shiftPerDay: number;
	electric: ContractInformation;
	diesel: ContractInformation;
	gas: ContractInformation;
	electricComp: ContractInformation;
}
