export class Financials {
	public truck: number;
	public body: number;
	public residualValue: number;
	public interest: number;
	public rmContract: number;
	public charger?: number; // Only for elec
	public smartCharge?: number; // Only for elec
	// Monthly fees
	public monthlyFeesTruck: number;
	public inclBody: number;
	public inclUpfront: number;
	public monthlyFeeCharger?: number; // Only for elec
	public inclUpfrontCharger?: number; // Only for elec
}
