import { TaxReductDetails } from './taxReductDetails';
import { UpfrontIncentive } from './upfrontIncentive';

export class IncentivesTaxe {
	upfront: number;
	upfronts?: UpfrontIncentive[];
	upfrontState: boolean;
	upfrontSaved: number;
	taxReduct: number;
	taxReductState: boolean;
	taxReductDetails: TaxReductDetails;
	taxReductSaved: number;
	taxOver: number;
	notUpfront: number;
	notUpfronts?: UpfrontIncentive[];
	notUpfrontState: boolean;
	notUpfrontSaved: number;
	toll: number;
	tollState: boolean;
	tollSaved: number;
	sharePercent: number;
	shareKm: number;
}
