<li *ngIf="!hasChildren">
  <a class="sidebar-item" role="menuitem" tabindex="-1" [routerLink]="url" routerLinkActive="active">{{ label }} </a>
</li>
<li *ngIf="hasChildren" rtChildRouterLinkActive rtAccordion>
  <span class="sidebar-item" role="separator" rtAccordionToggle>
    <span>{{ label }}</span>
  </span>
  <ul rtAccordionContent>
    <ng-content> </ng-content>
  </ul>
</li>
