import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICountry } from '../../models/business/iCountry';

@Component({
	selector: 'app-select-countries',
	templateUrl: './select-countries.component.html',
	styleUrls: ['./select-countries.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SelectCountriesComponent,
		},
	],
})
export class SelectCountriesComponent implements ControlValueAccessor, OnInit {
	@Input() public countries: ICountry[];
	@Input() public value: ICountry;
	@Output() valueChange = new EventEmitter();

	public toggleDropdown = false;
	public isHovered = false;
	public touched = false;
	public disabled = false;

	ngOnInit(): void {}

	onChange: any = () => {};
	onTouch: any = () => {};

	// writeValue() gets triggered whenever the parent component somehow changes the value of myFormControl
	public writeValue(input: ICountry) {
		this.value = input;
	}

	//Inform the parent component about changes inside of your CustomInputComponent, propagate changes of the value to the outside
	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	//Give Angular the means to understand when the custom form control is touched
	public registerOnTouched(onTouched: any): void {
		this.onTouch = onTouched;
	}

	public onSelect(country: ICountry): void {
		this.markAsTouched();
		if (!this.disabled) {
			this.value = country;
			this.valueChange.emit(this.value);
			this.isHovered = false;
			this.toggleDropdown = false;
		}
	}

	public markAsTouched(): void {
		if (!this.touched) {
			this.onTouch();
			this.touched = true;
		}
	}

	public displayDropdown(): void {
		this.markAsTouched();
		if (!this.toggleDropdown) this.toggleDropdown = !this.toggleDropdown;
	}

	public looseFocus(): void {
		if (!this.isHovered) {
			this.toggleDropdown = false;
		}
	}
}
