import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RtCurrentUser } from '../../models/rt-current-user.model';
import { RtSidebarService } from '../../services/rt-sidebar.service';

/**
 * Component responsible of displaying the header
 */
@Component({
  selector: 'rt-header',
  templateUrl: './rt-header.component.html',
  styleUrls: ['./rt-header.component.scss'],
})
export class RtHeaderComponent implements OnInit, OnDestroy {
  /**
   * The path to the logo displayed when sidebar is closed
   */
  @Input() smallLogo!: string;

  /**
   * The name of the application
   */
  @Input() applicationName!: string;

  /**
   * The current user connected
   */
  @Input() user!: RtCurrentUser;

  /**
   * The path to the root url of the application
   */
  @Input() rootPage!: string;

  /**
   * true in case the application should display header and sidebar, else false
   */
  @Input() withSidebar = true;

  /**
   * true if sidebar is opened, else false
   */
  isSidebarOpened!: boolean;

  /**
   * Subject used to avoid memory leaks on component destruction
   */
  destroy$ = new Subject<void>();

  /**
   * Default constructor
   * @param location object to interact with browser url
   * @param router router service
   * @param activatedRoute activated route service
   * @param sidebarService sidebar service
   */
  constructor(private router: Router, private sidebarService: RtSidebarService) {}

  /**
   * On init :
   * - Listen to the status of the sidebar emitted by the sidebar service
   * - Listen to router events to change the page title (mobile and not on root page)
   */
  ngOnInit(): void {
    if (this.withSidebar) {
      this.sidebarService
        .listenToIsOpened()
        .pipe(takeUntil(this.destroy$))
        .subscribe((isOpened: boolean) => {
          this.isSidebarOpened = isOpened;
        });
    }
  }

  /**
   * Method used to switch header design based on user location (root or not)
   * @returns true if root url, else false
   */
  isOnRootPage(): boolean {
    return this.router.url === this.rootPage;
  }

  /**
   * Method to redirect user to root page
   */
  goToRootPage(): void {
    this.router.navigateByUrl(this.rootPage);
  }

  /**
   * Method used when user clicks on the menu button to hide / display the sidebar
   */
  toggleSidebar(): void {
    if (this.withSidebar) {
      this.sidebarService.toggleSidebar(!this.isSidebarOpened);
    }
  }

  /**
   * on destroy, we release all subscriptions
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
