import { EmissionSourceDto } from '@_dto/global/vehicleCostDto/emissionSourceDto';
import { Comparison } from '@_models/comparison';
import { CountryEmissionFactor } from '@_models/vehicleCost/impactCo2/countryEmissionFactor';
import { EnergySubtype } from '@_models/vehicleCost/impactCo2/energySubtype';
import { Injectable } from '@angular/core';
import { GlobalConstant } from '@shared/GlobalConstant';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TcoBackendService } from '../api/tco-backend.service';
import { VehicleCostFormService } from '../forms/vehicle-cost-form.service';
import { EmissionSource } from './../../_models/vehicleCost/impactCo2/emissionSource';

@Injectable({
	providedIn: 'root',
})
export class EmissionFactorService {
	public comparison: Comparison;
	public energyConfigMap = new Map<number, string[]>([
		[GlobalConstant.COMPTYPE_ED, [GlobalConstant.ELECTRIC, GlobalConstant.DIESEL]],
		[GlobalConstant.COMPTYPE_EDG, [GlobalConstant.ELECTRIC, GlobalConstant.GAS, GlobalConstant.DIESEL]],
		[GlobalConstant.COMPTYPE_EG, [GlobalConstant.ELECTRIC, GlobalConstant.GAS]],
		[GlobalConstant.COMPTYPE_E, [GlobalConstant.ELECTRIC]],
	]);

	constructor(private dataService: TcoBackendService, protected vehicleCostFormService: VehicleCostFormService) {}

	public setComparisonForEmissionFactor(comparison: Comparison) {
		this.comparison = comparison;
	}

	public refreshEmissionFactorByEnergy(comparisonTypeId: number, countryCode: string, language: string): CountryEmissionFactor {
		const energies = this.energyConfigMap.get(comparisonTypeId) || [GlobalConstant.ELECTRIC];
		this.getEmissionFactorForAllEnergies(energies, countryCode, language);

		return this.comparison.vehicleCost.impactCo2;
	}

	public getEmissionFactorForAllEnergies(energies: string[], countryCode: string, language?: string) {
		this.getEmissionFactorByCountry(countryCode).subscribe((data: CountryEmissionFactor) => {
			this.updateEmissionFactoryByEnergy(energies, data);
			if (language) {
				this.updateEmissionSource(countryCode, language, this.comparison.vehicleCost.impactCo2, energies);
			}
		});
	}

	private getEmissionFactorByCountry(countryCode: string): Observable<CountryEmissionFactor> {
		return this.dataService.getEmissionFactorByCountry(countryCode).pipe(take(1));
	}

	public checkEmissionFactorLastUpdated(
		comparisonTypeId: number,
		countryCode: string,
		countryEmissionFactor: CountryEmissionFactor,
		energySubtypeName?: string
	): Observable<boolean> {
		const energies = this.energyConfigMap.get(comparisonTypeId) || [GlobalConstant.ELECTRIC];

		return this.getEmissionFactorByCountry(countryCode).pipe(
			map((data: CountryEmissionFactor) => {
				return energies.some((energy) =>
					this.isEmissionFactorUpdated(energy, data, countryEmissionFactor, energySubtypeName ?? '')
				);
			})
		);
	}

	private isEmissionFactorUpdated(energy: string, data: any, countryEmissionFactor: any, energySubtypeName: string): boolean {
		if (countryEmissionFactor[energy][0]?.value) {
			if (energy === GlobalConstant.DIESEL && energySubtypeName && energySubtypeName != GlobalConstant.BX) {
				return data[energy][1].value !== countryEmissionFactor[energy][0].value;
			}
			return data[energy][0].value !== countryEmissionFactor[energy][0].value;
		}
		return false;
	}

	private updateEmissionFactoryByEnergy(energies: string[], data: any) {
		let countryEmissionFactor: any = this.comparison.vehicleCost.impactCo2;

		energies.forEach((energy) => {
			countryEmissionFactor[energy] = data[energy];
			countryEmissionFactor[energy][0].emissionSource = new EmissionSource();
			let emissionValue = countryEmissionFactor[energy][0].value;

			if (energy === GlobalConstant.DIESEL) {
				this.updateEnergySubtypeForDiesel(countryEmissionFactor, data);
				countryEmissionFactor[energy][1].emissionSource = new EmissionSource();
				if (this.comparison.vehicleInfo.vehicleConfig.diesel.energySubtype?.name != 'Bx') {
					emissionValue = countryEmissionFactor[energy][1].value;
				}
			}

			this.vehicleCostFormService.impactCo2Form.patchValue({
				[energy]: { emissionFactor: emissionValue },
			});
		});
	}

	public updateEmissionSource(countryCode: string, language: string, countryEmissionFactor: CountryEmissionFactor, energies: string[]) {
		this.dataService.getEmissionSourceByCountry(countryCode, language).subscribe((data) => {
			let countryEmissionFactorMock: any = countryEmissionFactor;
			data.forEach((elm) => {
				energies.forEach((energy) => {
					const energyTypeCode = countryEmissionFactorMock[energy][0].energyType.code;
					this.updateEmissionSourceByEnergy(elm, energyTypeCode, countryEmissionFactor, energy);
				});
			});
		});
	}

	private updateEmissionSourceByEnergy(elm: EmissionSourceDto, energyTypeCode: string, data: any, energy: string) {
		if (elm.cdEnergyType === energyTypeCode) {
			data[energy].forEach((x: any) => {
				x.emissionSource = elm;
			});
		}
	}

	private updateEnergySubtypeForDiesel(countryEmissionFactor: any, data: any) {
		const energySubtype = this.getDieselEnergySubtype();
		if (energySubtype != null) {
			countryEmissionFactor.diesel[0] = data.diesel.find((elm: any) => elm.energySubtype.name === energySubtype.name);
		}
	}

	private getDieselEnergySubtype(): EnergySubtype {
		const dieselConfig = this.comparison.vehicleInfo.vehicleConfig.diesel;
		if (dieselConfig && dieselConfig.energySubtype) {
			return dieselConfig.energySubtype;
		}
		return new EnergySubtype();
	}
}
