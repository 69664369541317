<div class="relative w-full">
	<button
		id="countries"
		class="row-cols-12 w-full h-full border border-solid border-gray-300 bg-white text-gray-800 rounded-md shadow-sm text-left text-base cursor-pointer select-none focus:ring-1 focus:ring-gray-400"
		(click)="displayDropdown()"
		(focusout)="looseFocus()"
	>
		<div class="d-flex justify-content-center align-items-center">
			<!-- Country flag -->
			<div class="col-2 d-flex justify-content-center p-2 h-9 border-r border-solid border-gray-300 rounded-l-md">
				<span class="w-100 fi fi-{{ value.code.toLowerCase() }}"></span>
			</div>
			<!-- Country name -->
			<div class="col-8 pl-3">
				<span>{{ value.name }}</span>
			</div>
			<!-- arrow input -->
			<div class="col-2 d-flex justify-content-center pr-3 rounded-r-md border-gray-300">
				<svg class="w-4" viewBox="0 0 18 10" fill="none">
					<path d="M1 1L9 9L17 1" stroke="#252525" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</div>
			<!-- Countries dropdown -->
			<ul
				class="pl-0 absolute w-full top-full z-10 mt-0.5 text-base border border-solid border-gray-300 bg-white text-gray-800 shadow-md max-h-80 rounded-md overflow-auto select-none"
				(mouseover)="isHovered = true"
				(mouseout)="isHovered = false"
				[hidden]="!toggleDropdown"
			>
				<li
					class="grid grid-cols-12 text-gray-800 py-2 cursor-pointer hover:bg-zinc-100"
					*ngFor="let country of countries"
					id="{{ country.code }}"
					(mousedown)="onSelect(country)"
				>
					<div class="flex justify-center align-middle col-span-2 h-full">
						<span class="fi fi-{{ country.code.toLowerCase() }}"></span>
					</div>
					<span class="col-span-10 h-full pl-3">{{ country.name }}</span>
				</li>
			</ul>
		</div>
	</button>
</div>
