import { TotalDeliveryEnergyResultDto } from '@_dto/global/vehicleInfoDto/usageLimit/totalDeliveryEnergyResultDto';

export class TotalDeliveryEnergy {
	simulation: number;
	remainingEnergy: number;
	maxEnergy: number;
	status: string;

	constructor(totalDeliveryEnergyResultDto: TotalDeliveryEnergyResultDto) {
		this.simulation = totalDeliveryEnergyResultDto ? totalDeliveryEnergyResultDto.simulation : 0;
		this.remainingEnergy = totalDeliveryEnergyResultDto ? totalDeliveryEnergyResultDto.remainingEnergy : 0;
		this.maxEnergy = totalDeliveryEnergyResultDto ? totalDeliveryEnergyResultDto.maxEnergy : 0;
		this.status = totalDeliveryEnergyResultDto ? totalDeliveryEnergyResultDto.status : '';
	}
}
