import { ModelEnergy } from '@shared/models/business/modelEnergy';
import { BatteryVersion } from '@shared/models/business/batteryVersion';
import { EnergySubtype } from '../../vehicleCost/impactCo2/energySubtype';
import { Ptac } from '@shared/models/business/ptac';
import { Pto } from '@shared/models/business/pto';
import { Gcw } from '@shared/models/business/gcw';
import { EngineNumber } from '@shared/models/business/engineNumber';

export class EnergyConfig {
	model: ModelEnergy;
	ptac?: Ptac;
	gcw?: Gcw;
	engineNumber?: EngineNumber;
	pto: Pto;
	payload: number;
	battery?: BatteryVersion;
	energySubtype?: EnergySubtype;
}
