import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IStepModel } from '../_models/stepper';
import { GlobalConstant } from '@shared/GlobalConstant';
import { TcoFormService } from '../layout/comparator/_services/forms/tco-form.service';

const firstStepIndex = 0;
const lastStepIndex = 10;

@Injectable({
	providedIn: 'root',
})
export class StepsService {
	private steps$: BehaviorSubject<IStepModel[]> = new BehaviorSubject<IStepModel[]>(GlobalConstant.STEPS);
	private currentStep$: BehaviorSubject<IStepModel> = new BehaviorSubject<IStepModel>({
		stepIndex: 0,
		isComplete: false,
		name: '',
		route: '',
		fragment: '',
	});
	private initStep: IStepModel;
	private home: IStepModel = this.steps$.value[0];
	private tco1: IStepModel = this.steps$.value[1];
	private tco2: IStepModel = this.steps$.value[5];
	private results: IStepModel = this.steps$.value[10];

	constructor(private router: Router, private tcoForm: TcoFormService) {
		// Initialization of the steps
		const url = this.router.url.split('#');
		let initStep;
		if (url.length === 2) {
			initStep = GlobalConstant.STEPS.find((x) => x.fragment === url[1]);
		} else if (url.length === 1) {
			initStep = GlobalConstant.STEPS.find((x) => x.route === url[0]);
		} else console.error('wrong url :(');

		if (initStep) {
			this.initStep = initStep;
			this.currentStep$.next(this.initStep);
		} else {
			this.currentStep$.next(this.home);
		}
	}

	setCurrentStep(step: IStepModel): void {
		this.currentStep$.next(step);
	}

	getCurrentStep(): Observable<IStepModel> {
		return this.currentStep$.asObservable();
	}

	getSteps(): Observable<IStepModel[]> {
		return this.steps$.asObservable();
	}

	getBackRoute(): string {
		const currentRoute = this.currentStep$.value.route;

		if (!this.isFirstStep()) {
			switch (true) {
				case currentRoute.startsWith('/vehicle-info'): {
					const route = this.home.route;
					return route;
				}
				case currentRoute.startsWith('/vehicle-cost'): {
					const route = this.tco1.route;
					return route;
				}
				case currentRoute.startsWith('/results'): {
					const route = this.tco2.route;
					return route;
				}
				default:
					console.error('You somehow hit a wrong path!');
					return '/home';
			}
		} else {
			console.error('You somehow hit a wrong path!');
			return '/home';
		}
	}

	getNextRoute(): string {
		const currentRoute = this.currentStep$.value.route;
		if (!this.isLastStep()) {
			switch (true) {
				case currentRoute === '': {
					const route = this.tco1.route;
					return route;
				}
				case currentRoute.startsWith('/vehicle-info'): {
					const route = this.tco2.route;
					return route;
				}
				case currentRoute.startsWith('/vehicle-cost'): {
					const route = this.results.route;
					return route;
				}
				default:
					console.error('You somehow hit a wrong path!');
					return '/home';
			}
		} else {
			console.error('You somehow hit a wrong path!');
			return '/home';
		}
	}

	moveToBackRoute(): void {
		const currentRoute = this.currentStep$.value.route;
		if (!this.isFirstStep()) {
			switch (true) {
				case currentRoute.startsWith('/vehicle-info'):
					this.currentStep$.next(this.home);
					break;

				case currentRoute.startsWith('/vehicle-cost'):
					this.currentStep$.next(this.tco1);
					break;

				case currentRoute.startsWith('/results'):
					this.tcoForm.canDeactivate();
					if (!this.tcoForm.isBlock) {
						this.currentStep$.next(this.tco2);
					}
					break;

				default:
					console.error('You somehow hit a wrong path!');
					break;
			}
		}
	}

	moveToNextRoute(): void {
		const currentRoute = this.currentStep$.value.route;

		if (!this.isLastStep()) {
			switch (true) {
				case currentRoute === '':
					this.currentStep$.next(this.tco1);
					break;

				case currentRoute.startsWith('/vehicle-info'):
					this.currentStep$.next(this.tco2);
					break;

				case currentRoute.startsWith('/vehicle-cost'):
					this.tcoForm.canDeactivate();
					if (!this.tcoForm.isBlock) {
						this.currentStep$.next(this.results);
					}
					break;
				default:
					console.error('You somehow hit a wrong path!');
					break;
			}
		}
	}

	isFirstStep(): boolean {
		return this.currentStep$.value.stepIndex === 0;
	}

	isLastStep(): boolean {
		return this.currentStep$.value.stepIndex === this.steps$.value.length - 1;
	}
}
