import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RtCurrentUser } from '../../../../public-api';
import { RtSidebarService } from '../../services/rt-sidebar.service';

/**
 * Component responsible of displaying the sidebar
 */
@Component({
  selector: 'rt-sidebar',
  templateUrl: './rt-sidebar.component.html',
  styleUrls: ['./rt-sidebar.component.scss'],
})
export class RtSidebarComponent implements OnInit, OnDestroy {
  /**
   * Logo displayed on mobile and tablets display of the sidebar
   */
  @Input() smallLogo!: string;

  /**
   * Root page of the application
   */
  @Input() homepage!: string;

  /**
   * Current signed-in user
   */
  @Input() user!: RtCurrentUser;

  /**
   * Boolean to know if sidebar should be displayed or not, default set to false
   */
  isOpened = false;

  /**
   * Subject used to avoid memory leaks on component destruction
   */
  destroy$ = new Subject<void>();

  /**
   * Default constructor
   * @param sidebarService main related service
   */
  constructor(private sidebarService: RtSidebarService) {}

  /**
   * On init :
   * - Listen to the status of the sidebar emitted by the sidebar service
   */
  ngOnInit(): void {
    this.sidebarService.toggleSidebar(this.isOpened);
    this.sidebarService
      .listenToIsOpened()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isOpened) => {
        this.isOpened = isOpened;
      });
  }

  /**
   * On close button triggered :
   * - call sidebar service to close the sidebar
   */
  onClose(): void {
    this.sidebarService.toggleSidebar(false);
  }

  /**
   * On destroy, we release all subscriptions
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
