import { GlobalInformation } from './globalInformation';
import { VehicleCost } from './vehicleCost';
import { VehicleInformation } from './vehicleInformation';

export class Comparison {
	globalInfo!: GlobalInformation;
	vehicleInfo!: VehicleInformation;
	vehicleCost!: VehicleCost;
	date: Date;
}
