<!-- Black screen for mobile and tablets -->
<div class="black-screen" [class.opened]="isOpened" (click)="onClose()"></div>
<!-- Sidebar -->
<div class="sidebar" [class.opened]="isOpened">
  <button class="sidebar-close" type="button" (click)="onClose()" (keyup.enter)="onClose()" tabindex="0">
    <span class="zmdi zmdi-close"></span>
  </button>
  <span class="sidebar-logo" [routerLink]="homepage" (click)="onClose()">
    <img [src]="smallLogo" alt="Logo" class="logo-img" />
  </span>
  <nav role="menu" (click)="onClose()">
    <ul>
      <ng-content></ng-content>
    </ul>
  </nav>
  <div class="sidebar-profile">
    <span class="user-initials">{{
      [this.user.firstName.charAt(0).toUpperCase(), this.user.lastName.charAt(0).toUpperCase()].join('')
    }}</span>
    <span class="name">{{ [this.user.firstName, this.user.lastName].join(' ') }}</span>
  </div>
</div>
