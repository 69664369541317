<!-- ---------------------------------- -->
<!-- Header for tablets, laptops and up -->
<!-- ---------------------------------- -->
<header>
  <div class="application-language-header">Welcome to the {{ this.applicationName }} !</div>
  <div class="content-header">
    <!-- Logo -->
    <div class="logo-content" [class.opened]="isSidebarOpened">
      <img [src]="smallLogo" alt="Logo" class="logo-img" (click)="goToRootPage()" />
    </div>
    <!-- Main content -->
    <div class="header-content">
      <!-- Menu button and breadcrumb -->
      <div class="menu-content">
        <!-- Menu button -->
        <button *ngIf="withSidebar" type="button" class="menu-button" (click)="toggleSidebar()">
          <span class="zmdi zmdi-menu"></span>
        </button>
        <!-- Breadcrumb -->
        <!-- <rt-breadcrumbs [rootPage]="rootPage" *ngIf="!isSidebarOpened"></rt-breadcrumbs> -->
      </div>
      <!-- Other details -->
      <div class="details-content">
        <!-- User details-->
        <p class="user-info">
          <span class="user-name">{{ [this.user.firstName, this.user.lastName].join(' ') }}</span>
          <span *ngIf="user.oneLineInfo" class="user-one-line-info">{{ user.oneLineInfo }}</span>
        </p>
        <!-- User initials -->
        <span class="user-initials">{{
          [this.user.firstName.charAt(0).toUpperCase(), this.user.lastName.charAt(0).toUpperCase()].join('')
        }}</span>
      </div>
    </div>
  </div>
  <div class="phones">
    <div class="logo-content">
      <img [src]="smallLogo" alt="Logo" class="logo-img" />
    </div>
    <!-- Menu content -->
    <div class="menu-content">
      <button type="button" class="menu-button" (click)="toggleSidebar()">
        <span class="zmdi zmdi-menu"></span>
      </button>
    </div>
  </div>
</header>
