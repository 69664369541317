import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { IStepModel } from 'src/app/_models/stepper';
import { StepsService } from 'src/app/_services/steps.service';
import { Comparison } from '../../../layout/comparator/_models/comparison';
import { TcoFormService } from '../../../layout/comparator/_services/forms/tco-form.service';
import { ComparatorService } from '../../../layout/comparator/_services/logical/comparator.service';

@Component({
	selector: 'app-stepper',
	templateUrl: './stepper.component.html',
	styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
	steps$: Observable<IStepModel[]>;
	currentStep$: Observable<IStepModel>;
	steps: IStepModel[];
	public comparison: Comparison;

	constructor(
		private stepsService: StepsService,
		protected tcoForm: TcoFormService,
		private translate: TranslateService,
		private compService: ComparatorService
	) {}

	// TODO: Fix issues on CurrentStep;
	public ngOnInit(): void {
		this.steps$ = this.stepsService.getSteps();
		this.currentStep$ = this.stepsService.getCurrentStep();
		this.steps$.pipe(take(1)).subscribe((x) => (this.steps = x));
		this.getComparison();
	}

	public updateCurrentStep(step: any) {
		this.getComparison();
		if (this.tcoForm.isBlock && step.name === 'results') {
		} else {
			this.stepsService.setCurrentStep(step);
		}
	}

	public canDeactivate() {
		this.tcoForm.canDeactivate();
	}

	public getComparison() {
		this.compService.getComparisonObs().subscribe((data) => {
			this.comparison = data;
		});
	}
}
