export const environment = {
	production: false,
	auth: {
		clientId: 'ce219685-ff99-443b-be53-56b8ddc8c997',
		authority: 'https://login.microsoftonline.com/f25493ae-1c98-41d7-8a33-0be75f5fe603',
		audience: 'api://ce219685-ff99-443b-be53-56b8ddc8c997/',
		scope: 'access_as_user',
		redirectUri: location.origin,
	},
	backEndApiUrl: 'https://tco-api.azurewebsites.net',
	connectionString:
		'InstrumentationKey=21fec0af-adef-4994-86ec-0b10e9e1408f;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
};
