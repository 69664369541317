import { ICurrency } from '@shared/models/business/iCurrency';
import { IUnits } from '@shared/models/business/iUnits';
import { ICountry } from '@shared/models/business/iCountry';
import { IRange } from '@shared/models/business/iRange';
import { IComparisonType } from '@shared/models/business/iComparisonType';

export class GlobalInformation {
	id: number;
	name: string;
	companyName: string;
	contactMail: string;
	contactName: string;
	caseName: string;
	country: ICountry;
	currency: ICurrency;
	range: IRange;
	comparisonType: IComparisonType;
	unit: IUnits;
	isTco: boolean;
	isDetail: boolean;
	customerView: number;
	date: Date;
}
