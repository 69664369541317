import { Directive, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[rtButton]',
})
export class RtButtonDirective implements OnInit {
  @HostBinding('class') classes = 'rt-button';
  @Input() zmdiIcon!: string;
  @HostBinding('class.active') @Input() isActive = true;
  @HostBinding('class.big') @Input() isBig = false;
  @Input() color!: 'green' | 'red';
  @HostBinding('class.green') get green(): boolean {
    return this.color === 'green';
  }
  @HostBinding('class.red') get red(): boolean {
    return this.color === 'red';
  }

  /**
   * Default constructor for the directive
   * @param elementRef ElementRef accessor
   * @param renderer2 Renderer2 accessor
   */
  constructor(private elementRef: ElementRef, private renderer2: Renderer2) {}

  ngOnInit(): void {
    if (this.zmdiIcon) {
      const zmdiIcon = this.renderer2.createElement('span');
      this.renderer2.addClass(zmdiIcon, 'zmdi');
      this.renderer2.addClass(zmdiIcon, this.zmdiIcon);
      if (this.elementRef.nativeElement.innerText) {
        this.renderer2.insertBefore(this.elementRef.nativeElement, zmdiIcon, this.elementRef.nativeElement.firstChild);
      } else {
        this.renderer2.addClass(this.elementRef.nativeElement, 'round');
        this.renderer2.appendChild(this.elementRef.nativeElement, zmdiIcon);
      }
    }
  }
}
