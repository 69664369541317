import { EnergyUsage } from './vehicleInfo/energyUsage/energyUsage';
import { UsageLimit } from './vehicleInfo/usageLimit/usageLimit';
import { VehicleConfig } from './vehicleInfo/vehicleConfig/vehicleConfig';
import { VehicleUsage } from './vehicleInfo/vehicleUsage/vehicleUsage';

export class VehicleInformation {
	id!: number;
	vehicleUsage!: VehicleUsage;
	vehicleConfig!: VehicleConfig;
	energyUsage!: EnergyUsage;
	usageLimit!: UsageLimit;
}
