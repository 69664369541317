import { Component, Input } from '@angular/core';

/**
 * Component responsible of displaying a menu item in the sidebar
 */
@Component({
  selector: 'rt-menu-item',
  templateUrl: './rt-menu-item.component.html',
  styleUrls: ['./rt-menu-item.component.scss'],
})
export class RtMenuItemComponent {
  /**
   * ZMDI icon
   */
  @Input() icon!: string;

  /**
   * Displayed text
   */
  @Input() label!: string;

  /**
   * Targeted url
   */
  @Input() url!: string;

  /**
   * Used to know if the menu item has sub menu items or not, default set to false
   */
  @Input() hasChildren = false;
}
