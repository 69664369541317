import { TotalEnergyCost } from './vehicleCost/energyCost/totalEnergyCost';
import { FinancialsEnergy } from './vehicleCost/financials/financialsEnergy';
import { CountryEmissionFactor } from './vehicleCost/impactCo2/countryEmissionFactor';
import { EnergyTransportCost } from './vehicleCost/transport/energyTransportCost';
import { EnergyIncentivesTax } from './vehicleCost/incentives/energyIncentive';

export class VehicleCost {
	id!: number;
	financials: FinancialsEnergy;
	energyCost!: TotalEnergyCost;
	energyIncentivesTaxe!: EnergyIncentivesTax;
	impactCo2: CountryEmissionFactor;
	transportCost!: EnergyTransportCost;
}
