import { Injectable } from '@angular/core';
import { Comparison } from '@_models/comparison';
import { VehicleInformationService } from './vehicle-information-service';
import { EnergySessionStorage } from '../../../../core/sessionStorage/EnergySessionStorage ';
import { VehicleCostService } from './vehicle-cost.service';
import { EnergyConfig } from '@_models/vehicleInfo/vehicleConfig/energyConfig';
import { EnergyConsumption } from '@_models/vehicleInfo/energyUsage/energyConsumption';
import { EnergyCost } from '@_models/vehicleCost/energyCost/energyCost';
import { Financials } from '@_models/vehicleCost/financials/financials';
import { IncentivesTaxe } from '@_models/vehicleCost/incentives/incentivesTaxe';
import { CountryEmissionFactor } from '@_models/vehicleCost/impactCo2/countryEmissionFactor';
import { TransportCost } from '@_models/vehicleCost/transport/transportCost';
import { GlobalConstant } from '@shared/GlobalConstant';
import { ContractInformation } from '@_models/vehicleInfo/vehicleUsage/contract-information';

@Injectable({
	providedIn: 'root',
})
export class SessionStorageService {
	public electric = new EnergySessionStorage();
	public diesel = new EnergySessionStorage();
	public gas = new EnergySessionStorage();
	public electricComp = new EnergySessionStorage();

	constructor(private vehicleInfoService: VehicleInformationService, private vehicleCostService: VehicleCostService) {}

	public initDefaultDataInSessionStorage(comparison: Comparison) {
		this.initEnergyData(comparison, GlobalConstant.ELECTRIC);
		this.initEnergyData(comparison, GlobalConstant.DIESEL);
	}

	public initEnergyData(comparison: Comparison, energy: any) {
		// @ts-ignore
		this[energy].type = energy;

		if (energy != GlobalConstant.ELECTRIC) {
			comparison.vehicleInfo.vehicleConfig[energy].model.name = comparison.vehicleInfo.vehicleConfig[energy].model.code;
		}
		// @ts-ignore
		this[energy].energyConfig = comparison.vehicleInfo.vehicleConfig[energy];
		// @ts-ignore
		this[energy].energyUsage = comparison.vehicleInfo.energyUsage[energy];
		// @ts-ignore
		this[energy].financials = comparison.vehicleCost.financials[energy];
		// @ts-ignore
		this[energy].transportCost = comparison.vehicleCost.transportCost[energy];
		// @ts-ignore
		this[energy].energyIncentivesTaxe = comparison.vehicleCost.energyIncentivesTaxe[energy];
		// @ts-ignore
		this[energy].energyCost = comparison.vehicleCost.energyCost[energy];
		// @ts-ignore
		this[energy].energyContractInformation = comparison.vehicleInfo.vehicleUsage[energy];
		// @ts-ignore
		sessionStorage.setItem(this[energy].type, JSON.stringify(this[energy]));
	}

	public switchValue(comparison: Comparison, comparisonTypeId: number) {
		this.convertDataSessionToComp(comparison, GlobalConstant.ELECTRIC);
		switch (comparisonTypeId) {
			case GlobalConstant.COMPTYPE_ED:
				this.convertDataSessionToComp(comparison, GlobalConstant.DIESEL);
				break;
			case GlobalConstant.COMPTYPE_EDG:
				this.convertDataSessionToComp(comparison, GlobalConstant.DIESEL);
				this.convertDataSessionToComp(comparison, GlobalConstant.GAS);
				break;
			case GlobalConstant.COMPTYPE_EG:
				this.convertDataSessionToComp(comparison, GlobalConstant.GAS);
				break;
			case GlobalConstant.COMPTYPE_EE:
				this.convertDataSessionToComp(comparison, GlobalConstant.ELEC_COMP);
				break;
		}
	}

	public updateValueByEnergy(energy: string, data: any, replace?: string) {
		let property = data.constructor.name.charAt(0).toLowerCase() + data.constructor.name.slice(1);
		if (replace && property === 'object') {
			property = replace;
		}
		// @ts-ignore
		this[energy][property] = data;
		sessionStorage.removeItem(energy);
		// @ts-ignore
		sessionStorage.setItem(energy, JSON.stringify(this[energy]));
	}

	private convertDataSessionToComp(comparison: Comparison, energy: string) {
		if (sessionStorage.getItem(energy)) {
			comparison.vehicleInfo.vehicleConfig[energy] = new EnergyConfig();
			comparison.vehicleInfo.vehicleConfig[energy] = JSON.parse(sessionStorage[energy]).energyConfig;

			if (energy != GlobalConstant.ELECTRIC) {
				let model = {
					code: JSON.parse(sessionStorage[energy]).energyConfig.model.name,
					name: JSON.parse(sessionStorage[energy]).energyConfig.model.name,
				};
				comparison.vehicleInfo.vehicleConfig[energy].model = model;
			}
			comparison.vehicleInfo.energyUsage[energy] = JSON.parse(sessionStorage[energy]).energyUsage;
			comparison.vehicleCost.financials[energy] = JSON.parse(sessionStorage[energy]).financials;
			comparison.vehicleCost.energyIncentivesTaxe[energy] = JSON.parse(sessionStorage[energy]).energyIncentivesTaxe;
			comparison.vehicleCost.energyCost[energy] = JSON.parse(sessionStorage[energy]).energyCost;
			comparison.vehicleCost.transportCost[energy] = JSON.parse(sessionStorage[energy]).transportCost;
		} else {
			comparison.vehicleInfo.vehicleUsage[energy] = new ContractInformation();
			this.vehicleInfoService.loadDefaultContractDurationByEnergy(comparison.vehicleInfo.vehicleUsage, energy);
			comparison.vehicleInfo.vehicleConfig[energy] = new EnergyConfig();
			this.vehicleInfoService.loadDefaultVehicleConfigByEnergy(comparison.vehicleInfo.vehicleConfig, energy);
			comparison.vehicleInfo.energyUsage[energy] = new EnergyConsumption();
			this.vehicleInfoService.loadDefaultEnergyUsageByEnergy(comparison.vehicleInfo.energyUsage, energy);
			comparison.vehicleCost.financials[energy] = new Financials();
			this.vehicleCostService.loadDefaultFinancialsByEnergy(comparison.vehicleCost.financials, energy);
			comparison.vehicleCost.energyCost[energy] = new EnergyCost();
			this.vehicleCostService.loadDefaultEnergyCostByEnergy(comparison.vehicleCost.energyCost, energy);
			comparison.vehicleCost.energyIncentivesTaxe[energy] = new IncentivesTaxe();
			this.vehicleCostService.loadDefaultEnergyIncentivesTaxe(comparison.vehicleCost.energyIncentivesTaxe, energy);
			comparison.vehicleCost.transportCost[energy] = new TransportCost();
			this.vehicleCostService.loadDefaultTransportCostByEnergy(comparison.vehicleCost.transportCost, energy);
			comparison.vehicleCost.impactCo2[energy] = new CountryEmissionFactor();
			this.vehicleCostService.loadDefaultImpactCo2ByEnergy(comparison.vehicleCost.impactCo2, energy);
			this.initEnergyData(comparison, energy);
		}
	}

	public clearSessionStorage() {
		sessionStorage.clear();
	}
}
