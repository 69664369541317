import { ModelEnergy } from '../../models/business/modelEnergy';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IComparisonType } from '../../models/business/iComparisonType';
import { ICurrency } from '../../models/business/iCurrency';
import { IRange } from '../../models/business/iRange';
import { IUnits } from '../../models/business/iUnits';
import { EnergySubtype } from '../../../layout/comparator/_models/vehicleCost/impactCo2/energySubtype';
import { Application } from '../../models/business/application';
import { BatteryVersion } from '../../models/business/batteryVersion';
import { Ptac } from '../../models/business/ptac';
import { Pto } from '../../models/business/pto';
import { Gcw } from '@shared/models/business/gcw';
import { EngineNumber } from '@shared/models/business/engineNumber';
import { BodyType } from '@shared/models/business/bodyType';
import { UsageType } from '@shared/models/business/usageType';

type InputsType =
	| EnergySubtype
	| ICurrency
	| IUnits
	| IRange
	| IComparisonType
	| Application
	| ModelEnergy
	| BatteryVersion
	| Ptac
	| Pto
	| Gcw
	| EngineNumber
	| BodyType
	| UsageType;

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SelectComponent,
		},
	],
})
export class SelectComponent implements ControlValueAccessor, OnInit {
	@Input() public items: InputsType[];
	@Input() public items2: ICurrency[];
	@Input() public type: string;
	@Input() public isDisabled: boolean;
	@Input() public energy: string;

	@Output() valueChange = new EventEmitter();

	public value: InputsType;
	public value2: ICurrency;
	public toggleDropdown = false;
	public isHovered = false;
	public touched = false;
	public disabled = false;

	onChange: any = () => {};
	onTouch: any = () => {};

	constructor() {}

	ngOnInit(): void {}

	// writeValue() gets triggered whenever the parent component somehow changes the value of myFormControl
	public writeValue(input: InputsType) {
		if (this.type != 'ICurrency') {
			this.value = input;
		} else {
			this.value2 = input as ICurrency;
		}
	}

	//Inform the parent component about changes inside of your CustomInputComponent, propagate changes of the value to the outside
	public registerOnChange(onChange: any): void {
		this.onChange = onChange;
	}

	//Give Angular the means to understand when the custom form control is touched
	public registerOnTouched(onTouched: any): void {
		this.onTouch = onTouched;
	}

	public markAsTouched(): void {
		if (!this.touched) {
			this.onTouch();
			this.touched = true;
		}
	}

	public displayDropdown(): void {
		this.markAsTouched();
		if (!this.toggleDropdown) this.toggleDropdown = !this.toggleDropdown;
	}

	public looseFocus() {
		if (!this.isHovered) this.toggleDropdown = false;
	}

	public onSelect(item: InputsType): void {
		this.markAsTouched();
		if (!this.disabled) {
			if (this.type != 'ICurrency') {
				this.value = item;
				this.valueChange.emit(this.value);
			} else {
				this.value2 = item as ICurrency;
				this.valueChange.emit(this.value2);
			}

			this.toggleDropdown = !this.toggleDropdown;
			this.isHovered = false;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}
}
