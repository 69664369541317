<div class="fixed w-full top-[8.286rem] bg-zinc-50 z-20 pt-3 pb-[3.75rem] px-5 tablet">
	<div class="flex justify-center items-center w-75 mx-auto">
		<ng-container *ngFor="let step of steps$ | async; let i = index">
			<a
				[routerLink]="[step.route]"
				id="{{ step.name }}"
				fragment="{{ step.fragment }}"
				class="text-dark"
				(click)="updateCurrentStep(step); step.name === 'results' ? tcoForm.canDeactivate() : ''"
			>
				<div class="relative">
					<div
						[ngClass]="(currentStep$ | async)!.stepIndex === step.stepIndex ? 'bg-danger' : 'bg-white'"
						class="w-4 h-4 border border-solid border-danger rounded-xl"
					></div>
					<div
						[ngClass]="{ 'font-semibold': (currentStep$ | async)!.stepIndex === step.stepIndex }"
						class="absolute left-1/2 top-6 text-center text label select-none"
					>
						{{ "step." + step.name | translate }}
					</div>
				</div>
			</a>
			<div *ngIf="i < (steps$ | async)!.length - 1" class="w-20 border-dashed"></div>
		</ng-container>
	</div>
</div>
