<!-- Breadcrumbs -->
<nav class="breadcrumb" role="navigation" aria-label="Breadcrumbs">
  <!-- For each item in the breadcrumbs -->
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
    <!-- Display the label if current page -->
    <span class="breadcrumb-end" *ngIf="i === breadcrumbs.length - 1; else breadcrumbLink">
      {{ breadcrumb.label }}</span
    >
    <!-- Else, associate a link with the label-->
    <ng-template #breadcrumbLink>
      <!-- To display the home icon as breadcrumb item -->
      <a class="breadcrumb-hidden" *ngIf="breadcrumb.url === rootPage" [routerLink]="rootPage">
        <span class="zmdi zmdi-home"></span>
      </a>
      <!-- Rest of the link / label items -->
      <a
        class="breadcrumb-hidden"
        *ngIf="breadcrumb.url && breadcrumb.url !== rootPage; else hasNotOwnUrl"
        [routerLink]="breadcrumb.url"
        #ok
      >
        {{ breadcrumb.label }}
        <span class="zmdi zmdi-chevron-right breadcrumb-hidden"></span>
      </a>
      <ng-template #hasNotOwnUrl>
        <span>
          {{ breadcrumb.label }}
        </span>
        <span class="zmdi zmdi-chevron-right breadcrumb-hidden"></span>
      </ng-template>
    </ng-template>
  </ng-container>
</nav>
