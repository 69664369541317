import { IncentivesTaxe } from './incentivesTaxe';

export class EnergyIncentivesTax {
	[key: string]: any;
	incomeTax: number;
	electric: IncentivesTaxe;
	diesel: IncentivesTaxe;
	gas: IncentivesTaxe;
	electricComp: IncentivesTaxe;
}
