<li *ngIf="!hasChildren">
  <a class="sidebar-item" role="menuitem" tabindex="-1" [routerLink]="url" routerLinkActive="active">
    <span class="menu-icon" [ngClass]="icon"></span>
    <span>{{ label }}</span>
  </a>
</li>
<li *ngIf="hasChildren" rtChildRouterLinkActive rtAccordion>
  <span class="sidebar-item no-circle" role="separator" rtAccordionToggle>
    <span class="menu-icon" [ngClass]="icon"></span>
    <span>{{ label }}</span>
  </span>
  <ul #subMenuItemsList rtAccordionContent>
    <ng-content> </ng-content>
  </ul>
</li>
