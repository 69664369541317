import { DailyMissionResultDto } from '@_dto/global/vehicleInfoDto/usageLimit/dailyMissionResultDto';

export class DailyMission {
	simulation: number;
	maxBeginningOfLife: number;
	maxEndOfLife: number;
	status: string;

	constructor(dailyMissionResultDto: DailyMissionResultDto) {
		this.simulation = dailyMissionResultDto ? dailyMissionResultDto.simulation : 0;
		this.maxBeginningOfLife = dailyMissionResultDto ? dailyMissionResultDto.maxBeginningOfLife : 0;
		this.maxEndOfLife = dailyMissionResultDto ? dailyMissionResultDto.maxEndOfLife : 0;
		this.status = dailyMissionResultDto ? dailyMissionResultDto.status : '';
	}
}
