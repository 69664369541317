import { EmissionSource } from './emissionSource';
import { EnergySubtype } from './energySubtype';
import { EnergyType } from './energyType';

export class EmissionFactor {
	energyType: EnergyType;
	energySubtype: EnergySubtype;
	value: number;
	emissionSource: EmissionSource;
}
