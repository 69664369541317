<div class="z-10 text-xs bg-sky-200 rounded-lg" *ngIf="modalIsOpen">
	<button type="button" class="float-right">
		<span style="color: black !important; font-size: 20px" aria-hidden="true" (click)="closeModal()">&times;</span>
	</button>
	<p class="px-2 pt-3 mb-1">{{ "global.alert" | translate }}</p>
	<ul class="px-2 pb-1 mb-1" *ngFor="let alerts of alertMaps.get(property); let index = index">
		<div *ngIf="property === 'application'">
			<span *ngIf="index === 0">{{ "energyUsage.title" | translate }}</span>
			<span *ngIf="index === 1">{{ "financials.title" | translate }}</span>
		</div>
		<div>
			<span *ngIf="property != 'application'">{{ "financials.title" | translate }}</span>
		</div>
		<li class="pl-2" *ngFor="let a of alerts" style="list-style: inside !important">{{ "" + a + "" | translate }}</li>
	</ul>
</div>
