import { DebugElement } from '@angular/core';
import { Directive, Input, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[clickOutside]',
})
export class ClickOutsideDirective {
	@Output() clickOutside = new EventEmitter<void>();

	constructor(private elementRef: ElementRef) {}

	@HostListener('document:mousedown', ['$event.target'])
	public onClick(target: any) {
		const clickedInside = this.elementRef.nativeElement.contains(target);
		const clickedButton = window.document.querySelector('#loadButton') === target;
		const clickedConfirmDiv = window.document.querySelector('#confirmDiv')?.contains(target);
		if (!clickedInside && !clickedButton && !clickedConfirmDiv) {
			this.clickOutside.emit();
		}
	}
}
