import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

export class Utils {
	constructor(private toastr: ToastrService, private translate: TranslateService) {}

	public displaySuccessMessage(message: string, message2 = '') {
		if (message2 !== '') {
			this.translate.get(message).subscribe((text: string) => {
				this.translate.get(message2).subscribe((text2: string) => {
					this.toastr.success(text, text2);
				});
			});
		} else {
			this.translate.get(message).subscribe((text: string) => {
				this.toastr.success(text);
			});
		}
	}

	public displayErrorMessageParam(message: string, param: Object) {
		this.translate.get(message, param).subscribe((text: string) => {
			this.toastr.error(text);
		});
	}

	public displayErrorMessage(message: string, message2 = '') {
		if (message2 !== '') {
			this.translate.get(message).subscribe((text: string) => {
				this.translate.get(message2).subscribe((text2: string) => {
					this.toastr.error(text, text2);
				});
			});
		} else {
			this.translate.get(message).subscribe((text: string) => {
				this.toastr.error(text);
			});
		}
	}

	public displayWarningMessage(message: string, message2 = '') {
		if (message2 !== '') {
			this.translate.get(message).subscribe((text: string) => {
				this.translate.get(message2).subscribe((text2: string) => {
					this.toastr.warning(text, text2);
				});
			});
		} else {
			this.translate.get(message).subscribe((text: string) => {
				this.toastr.warning(text);
			});
		}
	}

	public displayInfoMessage(message: string, message2 = '') {
		if (message2 !== '') {
			this.translate.get(message).subscribe((text: string) => {
				this.translate.get(message2).subscribe((text2: string) => {
					this.toastr.info(text, text2);
				});
			});
		} else {
			this.translate.get(message).subscribe((text: string) => {
				this.toastr.info(text);
			});
		}
	}
}
