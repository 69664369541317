import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[onlyDigit]',
})
export class OnlyDigitDirective {
	constructor() {}

	@HostListener('keydown', ['$event'])
	ngOnChanges(evt: any) {
		const charCode = evt.which ? evt.which : evt.keyCode;
		if ([107, 69, 109, 45].includes(charCode)) {
			return false;
		}
		return true;
	}
}
