import { ContractDuration } from '@_models/vehicleInfo/usageLimit/contractDuration';
import { DailyMission } from '@_models/vehicleInfo/usageLimit/dailyMission';
import { TotalDeliveryEnergy } from '@_models/vehicleInfo/usageLimit/totalDeliveryEnergy';
import { UsageLimitDto } from '@_dto/global/vehicleInfoDto/usageLimit/usageLimitDto';

export class UsageLimit {
	dailyMission: DailyMission;
	totalDeliveredEnergy: TotalDeliveryEnergy;
	contractDuration: ContractDuration;

	constructor(usageLimitDto?: UsageLimitDto) {
		if (usageLimitDto) {
			this.dailyMission = new DailyMission(usageLimitDto.dailyMissionResult);
			this.contractDuration = new ContractDuration(usageLimitDto.contractDurationResult);
			this.totalDeliveredEnergy = new TotalDeliveryEnergy(usageLimitDto.totalDeliveryEnergyResult);
		}
	}
}
