import { Input, Component } from '@angular/core';

/**
 * Component responsible of displaying sub-sub-menu items
 */
@Component({
  selector: 'rt-sub-sub-menu-item',
  templateUrl: './rt-sub-sub-menu-item.component.html',
  styleUrls: ['./rt-sub-sub-menu-item.component.scss'],
})
export class RtSubSubMenuItemComponent {
  /**
   * Sub sub menu label
   */
  @Input() label!: string;

  /**
   * Targeted url
   */
  @Input() url!: string;
}
